import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {makeCurrentSubscriptionState} from '../../redux/subscriptions/selectors'
import SimpleLoader from '../../components/SimpleLoader'
import {Table, Header, Button, Segment, Icon, Image, Modal, List, Message} from 'semantic-ui-react'
import {getTransactions} from '../../redux/transactions/thunks'
import {getSubscriptions} from '../../redux/subscriptions/thunks'
import {UserContext} from '../../contexts/userContext'
import {PageContext} from '../../contexts/pageContext'
import {productNames, productLogos, productDescriptions} from '../../utils/configs'
import {importAllImages} from '../../utils/imageHelpers'

const PropertyNameEnum = {
  LOGO: 'LOGO',
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION'
}

const ProductTypeEnum = {
  BASE: 'BASE',
  ENHANCEMENT: 'ENHANCEMENT'
}

class TransactionContainer extends React.Component {
  static contextType = PageContext
  rowRefs = {}
  constructor(props) {
    super(props)
    this.state = {
      enableContainer: false,
      isSubscriptionDetailOpen: false,
      activeRowSubscriptionItems: {subscriptionId: null, items: [], transaction: {transactionDate: null}},
      pageSize: 20,
      images: importAllImages()
    }

    // this.showSubscriptionDetail = this.showSubscriptionDetail.bind(this)
    this.handlePageChanged = this.handlePageChanged.bind(this)
    this.onSubscriptionDetailClose = this.onSubscriptionDetailClose.bind(this)
  }

  componentDidMount() {
    const {getSubscriptions, getTransactionHistory} = this.props
    this.setState({
      enableContainer: false
    })

    Promise.all([getSubscriptions(), getTransactionHistory(10)]).then(() => this.setState({enableContainer: true}))
    this.context.setPageSubHeader('BILLING HISTORY')
  }

  componentWillUnmount() {
    // transaction history can take a loooong time to return...this prevents errors from being thrown for user giving up
    this.props.transactions.cancelRequest()
  }

  onSubscriptionDetailClose = () => {
    this.setState({
      isSubscriptionDetailOpen: false,
      activeRowSubscriptionItems: {subscriptionId: null, items: [], transaction: {transactionDate: null}}
    })
  }

  handlePageChanged = (event, {name}) => {
    const {pageSize} = this.state
    // create aliases for the transaction.next and .previous props
    const {
      transactions: {previous: prevUrl, next: nextUrl}
    } = this.props

    let url = null
    switch (name) {
      case 'nextItem':
        url = nextUrl
        break
      case 'prevItem':
        url = prevUrl
        break
      // no default
    }

    this.setState({enableContainer: false}, () =>
      this.props.getTransactionHistory(pageSize, url).then(() => this.setState({enableContainer: true}))
    )
  }

  showSubscriptionDetail = (event, data) => {
    const {transactions, subscriptions} = this.props
    const rowTransactionId = event.currentTarget.className
    const transaction = transactions.data.find((t) => t.id === rowTransactionId)
    if (!transaction.subscription) return

    const transactionDate = transaction.status_log.data.find((log) => log.status === 'Captured' || log.status === 'Refunded')
      .created

    this.setState({
      isSubscriptionDetailOpen: true,
      activeRowSubscriptionItems: {
        transaction: {
          id: rowTransactionId,
          currency: transaction.currency,
          transactionItems: transaction.items.data,
          transactionDate: transactionDate
        },
        subscriptionId: transaction.subscription.id,
        items: subscriptions.subscriptionModel.data.find((s) => s.id === transaction.subscription.id).items.data
      }
    })
  }

  getProductInfo = ({productTypeEnum, productId, propertyNameEnum, subscriptionId}) => {
    const {products, subscriptions, currentSubscription} = this.props
    const {images} = this.state

    const subscription = subscriptions.subscriptionModel.data.find((s) => s.id === subscriptionId || currentSubscription.id)
    if (!subscription) return undefined
    const subscriptionItems = subscription.items.data.flatMap(
      (subItem) =>
        !products[subItem.product.id]
          ? []
          : products[subItem.product.id].productType !== productTypeEnum
            ? []
            : !productId
              ? [subItem]
              : subItem.product.id !== productId
                ? []
                : [subItem]
    )
    if (subscriptionItems.length === 0) return undefined

    const propMap = subscriptionItems.map((subItem) => {
      switch (propertyNameEnum) {
        case PropertyNameEnum.LOGO:
          return images[productLogos[subItem.product.id]]
        case PropertyNameEnum.NAME:
          return productNames[subItem.product.id]
        case PropertyNameEnum.DESCRIPTION:
          return productDescriptions[subItem.product.id]
        default:
          return products[subItem.product.id][propertyNameEnum]
      }
    })

    return propMap.length < 2 ? propMap.shift() : propMap
  }

  render() {
    const {enableContainer, isSubscriptionDetailOpen, activeRowSubscriptionItems} = this.state
    const {transactions, products} = this.props
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <SimpleLoader
            active={!enableContainer}
            message="Getting Transaction History"
            description="...this can take several mintues..."
          >
            <Segment raised>
              <Table stackable celled striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Subscription Info</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!transactions.data
                    ? null
                    : transactions.data.map((transaction) => (
                        <Table.Row key={transaction.id} onClick={this.showSubscriptionDetail} className={transaction.id}>
                          <Table.Cell collapsing>
                            <Icon name="magnify" />
                          </Table.Cell>
                          <Table.Cell collapsing>
                            <Icon name="calendar alternate outline" />
                            {new Date(
                              transaction.status_log.data.find(
                                (log) => log.status === 'Captured' || log.status === 'Refunded'
                              ).created
                            ).toLocaleDateString(userContext.locale, {
                              month: '2-digit',
                              year: 'numeric',
                              day: '2-digit'
                            })}
                          </Table.Cell>
                          <Table.Cell>
                            {this.getProductInfo({
                              productTypeEnum: ProductTypeEnum.BASE,
                              propertyNameEnum: PropertyNameEnum.NAME,
                              subscriptionId: transaction.subscription.id
                            })}
                            :{' '}
                            {this.getProductInfo({
                              productTypeEnum: ProductTypeEnum.BASE,
                              propertyNameEnum: PropertyNameEnum.DESCRIPTION,
                              subscriptionId: transaction.subscription.id
                            })}
                          </Table.Cell>
                          <Table.Cell collapsing>
                            {transaction.status_log.data.find(
                              (log) => log.status === 'Captured' || log.status === 'Refunded'
                            ).status === 'Captured'
                              ? 'Paid'
                              : 'Refunded'}
                          </Table.Cell>
                          <Table.Cell collapsing>
                            <Icon name={transaction.currency.toLowerCase()} />
                            {transaction.amount}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                      <Link to={{pathname: '/subscription', search: '', hash: '', state: undefined}}>
                        <Button floated="right">Exit</Button>
                      </Link>
                      <Button.Group>
                        <Button name="firstItem" onClick={this.handlePageChanged}>
                          <Icon name="double angle left" />
                        </Button>
                        <Button name="prevItem" onClick={this.handlePageChanged}>
                          <Icon name="angle left" />
                        </Button>
                        <Button name="nextItem" onClick={this.handlePageChanged}>
                          <Icon name="angle right" />
                        </Button>
                      </Button.Group>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>

            <Modal
              closeIcon={<Icon name="close" />}
              closeOnDimmerClick
              open={isSubscriptionDetailOpen}
              onClose={this.onSubscriptionDetailClose}
            >
              <Modal.Header>Subscription Items Detail</Modal.Header>
              <Modal.Content image>
                <Image
                  wrapped
                  size="medium"
                  src={this.getProductInfo({
                    productTypeEnum: ProductTypeEnum.BASE,
                    propertyNameEnum: PropertyNameEnum.LOGO,
                    subscriptionId: activeRowSubscriptionItems.subscriptionId
                  })}
                />
                <Modal.Description>
                  <Header>
                    Billing Details for{' '}
                    {new Date(activeRowSubscriptionItems.transaction.transactionDate).toLocaleDateString(
                      userContext.locale,
                      {
                        month: '2-digit',
                        year: 'numeric',
                        day: '2-digit'
                      }
                    )}
                  </Header>
                  <List relaxed="very" divided verticalAlign="middle">
                    {activeRowSubscriptionItems.items.map((subItem) => (
                      <List.Item key={subItem.id}>
                        <List.Content floated="right">
                          <Icon name={activeRowSubscriptionItems.transaction.currency.toLowerCase()} />
                          {activeRowSubscriptionItems.transaction.transactionItems.find((t) => t.sku === subItem.product.id)
                            .total || 0}
                        </List.Content>
                        <List.Header>
                          {this.getProductInfo({
                            productTypeEnum: products[subItem.product.id].productType,
                            productId: subItem.product.id,
                            propertyNameEnum: PropertyNameEnum.NAME,
                            subscriptionId: activeRowSubscriptionItems.subscriptionId
                          })}
                        </List.Header>
                        <List.Description>
                          {this.getProductInfo({
                            productTypeEnum: products[subItem.product.id].productType,
                            productId: subItem.product.id,
                            propertyNameEnum: PropertyNameEnum.DESCRIPTION,
                            subscriptionId: activeRowSubscriptionItems.subscriptionId
                          })}
                        </List.Description>
                      </List.Item>
                    ))}
                  </List>
                  <Message floating size="mini" color="olive" content="*THESE PRICES DO NOT REFLECT SALES/VAT TAXES" />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </SimpleLoader>
        )}
      </UserContext.Consumer>
    )
  }
}

const makeMapStateToProps = () => {
  const currentSubscription = makeCurrentSubscriptionState()
  return (state, props) => ({
    account: state.cashBoxAccount,
    products: state.products,
    subscriptions: state.subscriptions,
    currentSubscription: currentSubscription(state.subscriptions.subscriptionModel),
    transactions: state.transactions
  })
}

const mapDispatchToProps = (dispatch) => ({
  getTransactionHistory: (pageSize, nextUrl) => {
    return dispatch(getTransactions(pageSize, nextUrl))
  },
  getSubscriptions: () => {
    return dispatch(getSubscriptions())
  }
})

export default connect(
  makeMapStateToProps(),
  mapDispatchToProps
)(TransactionContainer)
