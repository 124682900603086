import jsonwebtoken from 'jsonwebtoken'

export default function verifier(jwtString) {
  let dataDecoded
  let errDecoded
  let decoded
  if (!jwtString) {
    errDecoded = {
      status: 500,
      statusText: 'Internal Server Error'
    }
  } else {
    let key = process.env.REACT_APP_CASHBOX_JWT_ORBIS
    let keyCB = process.env.REACT_APP_CASHBOX_JWT_CB || process.env.REACT_APP_CASHBOX_JWT_ORBIS
    try {
      decoded = jsonwebtoken.decode(jwtString)
      // decoded = jsonwebtoken.verify(jwtString, key, {
      //   algorithms: ['RS256']
      // })
    } catch (err) {
      switch (err.name) {
        case 'TokenExpiredError':
          decoded = jsonwebtoken.decode(jwtString)
          errDecoded = {
            status: 554,
            statusText: 'Session Timeout'
          }
          break
        default:
          try {
            decoded = jsonwebtoken.verify(jwtString, keyCB, {
              algorithms: ['ES256']
            })
          } catch (err) {
            decoded = jsonwebtoken.decode(jwtString)
            switch (err.name) {
              case 'TokenExpiredError':
                decoded = jsonwebtoken.decode(jwtString)
                errDecoded = {
                  status: 554,
                  statusText: 'Session Timeout'
                }
                break
              case 'JsonWebTokenError':
              case 'TypeError':
              default:
                errDecoded = {
                  status: 500,
                  statusText: 'Internal Server Error'
                }
            }
          }
      }
    }
  }
  //Turn off verifier
  // dataDecoded = decoded
  // errDecoded = null
  //Turn on verifier
  if (decoded) {
    dataDecoded = decoded
  } else {
    errDecoded = {
      status: 500,
      statusText: 'Internal Server Error'
    }
  }
  return {dataDecoded, errDecoded}
}
