import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {withRouter} from 'react-router'
import Callback from './containers/callback/Callback'
import CoreLayout from './layouts/CoreLayout'
import MarketingLayout from './layouts/MarketingLayout'
import SubscriptionContainer from './containers/subscription/SubscriptionContainer'
import EnhancementsContainer from './containers/products/EnhancementsContainer'
import PaymentContainer from './containers/billing/PaymentContainer'
import SummaryContainer from './containers/billing/SummaryContainer'
import {UserContext} from './contexts/userContext'
import PackagesContainer from './containers/products/PackagesContainer'
import TransactionsContainer from './containers/billing/TransactionsContainer'

class Routes extends React.Component {
  static contextType = UserContext

  handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      this.context.authService.handleAuthentication()
    }
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => {
            return <MarketingLayout showHomepageHeading {...props} />
          }}
        />
        <Route
          path="/callback"
          render={(props) => {
            this.handleAuthentication(props)
            return (
              <CoreLayout disabled={true}>
                <Callback {...props} />
              </CoreLayout>
            )
          }}
        />
        <CoreLayout>
          <Route path="/packages" component={PackagesContainer} />
          <Route exact path="/subscription" component={SubscriptionContainer} />
          <Route path="/subscription/products/packages" component={SubscriptionContainer} />
          <Route path="/subscription/products/enhancements" component={EnhancementsContainer} />
          <Route path="/subscription/billing/payment" component={PaymentContainer} />
          <Route path="/billing/payment" component={PaymentContainer} />
          <Route path="/subscription/billing/summary" component={SummaryContainer} />
          <Route path="/billing/transactions" component={TransactionsContainer} />
        </CoreLayout>
      </Switch>
    )
  }
}

export default withRouter(Routes)
