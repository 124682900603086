import axios from 'axios'
import verifier from '../../utils/jwtVerifier'
import authService from '../../services/authService'
import getClientIP from '../../utils/getClientIP'
import {
  webSessionInitializePaymentRequest,
  webSessionInitializePaymentFailure,
  webSessionInitializePaymentSuccess,
  webSessionFinalizePaymentRequest,
  webSessionFinalizePaymentFailure,
  webSessionFinalizePaymentSuccess,
  webSessionFinalizePaymentReset
} from './actions.js'
import {addPaymentMethod} from '../subscriptions/thunks'
import {updateAccountPaymentMethod} from '../accounts/thunks'

const axiosClientSOAP = axios.create({
  baseURL: process.env.REACT_APP_CASHBOX_SOAP_PROXY,
  headers: {'Content-Type': 'text/xml; charset=UTF-8'}
})

export function getWebSessionInitializePayment() {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          webSessionInitializePaymentFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText
          })
        )
      )
    }

    const accountId = dataDecoded.sub

    return getClientIP().then((clientIP) => {
      const parms = {merchantAccountId: accountId, clientIP: clientIP}
      const soapRequest = axiosClientSOAP.post(`/WebSessionInitializePayment?account=${encodeURI(accountId)}`, {
        parms,
        resultElement: 'initializeResponse',
        selfcareEnv: process.env.REACT_APP_SELFCARE_ENV,
        selfcareName: process.env.REACT_APP_SELFCARE_NAME,
        selfcareVersion: process.env.REACT_APP_SELFCARE_VERSION
      })

      dispatch(webSessionFinalizePaymentReset())
      dispatch(webSessionInitializePaymentRequest())

      return soapRequest.then(function(response) {
        let data = response.data.initializeResponse
        if (data) {
          if (data.return.returnCode < 400) {
            dispatch(addPaymentMethod(data.session.privateFormValues[1].value))
            dispatch(webSessionInitializePaymentSuccess(data))
            return data
          } else {
            response.status = data.return.returnCode
            response.statusText = data.return.returnString
            dispatch(webSessionInitializePaymentFailure(response))
            throw new Error(response.statusText)
          }
        }
      })
    })
  }
}

export function getWebSessionFinalizePayment(parms = {}) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          webSessionFinalizePaymentFailure({
            returnCode: errDecoded.status,
            returnString: errDecoded.statusText
          })
        )
      )
    }
    const accountId = dataDecoded.sub
    if (!accountId) {
      return Promise.reject(
        dispatch(
          webSessionFinalizePaymentFailure({
            status: 500,
            statusText: 'no account specified'
          })
        )
      )
    }
    const soapRequest = axiosClientSOAP.post(`/WebSessionFinalizePayment?account=${encodeURI(accountId)}`, {
      parms,
      resultElement: 'finalizeResponse',
      selfcareEnv: process.env.REACT_APP_SELFCARE_ENV,
      selfcareName: process.env.REACT_APP_SELFCARE_NAME,
      selfcareVersion: process.env.REACT_APP_SELFCARE_VERSION
    })

    dispatch(webSessionFinalizePaymentRequest())
    return soapRequest.then(function(response) {
      let data = response.data.finalizeResponse
      if (data.return.returnCode < 400) {
        if (data.session && data.session.apiReturn) {
          if (data.session.apiReturn.returnCode < 400) {
            data.session.status = data.session.apiReturn.returnCode
            data.session.statusText = data.session.apiReturn.returnString
            dispatch(updateAccountPaymentMethod(data.session.apiReturnValues.accountUpdatePaymentMethod.account))
            dispatch(webSessionFinalizePaymentSuccess(data.session))
            return data.session
          } else {
            data.session.status = data.session.apiReturn.returnCode
            data.session.statusText = data.session.apiReturn.returnString
            dispatch(webSessionFinalizePaymentFailure(data.session))
            throw new Error(data.session.statusText)
          }
        } else {
          response.status = '1402'
          response.statusText = '' //override in Error.js
          dispatch(webSessionFinalizePaymentFailure(response))
          throw new Error(response.status)
        }
      } else {
        response.status = data.return.returnCode
        response.statusText = data.return.returnString
        dispatch(webSessionFinalizePaymentFailure(response))
        throw new Error(response.statusText)
      }
    })
  }
}

export function getWebSessionFinalizePaymentReset() {
  return function(dispatch) {
    return Promise.resolve(dispatch(webSessionFinalizePaymentReset))
  }
}
