import axios from 'axios'
import verifier from '../../utils/jwtVerifier'
import {transactionsFailure, transactionsRequest, transactionsSuccess} from './actions'
import AuthService from '../../services/authService'

const authService = new AuthService()
const CancelToken = axios.CancelToken
let cancel = () => {}

const axiosClient = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

export function getTransactions(pageSize, nextUrl) {
  return function(dispatch, getState) {
    const sessionToken = authService.getIdToken()
    const {dataDecoded, errDecoded} = verifier(sessionToken)

    if (errDecoded) {
      return dispatch(
        transactionsFailure({
          status: errDecoded.status,
          statusText: errDecoded.statusText
        })
      )
    }

    const accountID = dataDecoded.sub

    const resolvedTransactions = axiosClient.get(
      nextUrl || `/transactions?account=${encodeURI(accountID)}&limit=${pageSize}`,
      {headers: {Authorization: authService.getIdToken()}},
      {cancelToken: new CancelToken((c) => (cancel = c))}
    )

    dispatch(transactionsRequest(cancel))
    return resolvedTransactions
      .then((response) => {
        if (response.data.object !== 'Error') {
          const billingTransactions = response.data.data.flatMap(
            (transaction) =>
              transaction.status_log.data.some((logItem) => logItem.status === 'Captured' || logItem.status === 'Refunded')
                ? [transaction]
                : []
          )
          response.data.data = billingTransactions
          dispatch(transactionsSuccess(response.data))
        } else {
          response.status = 500
          response.statusText = '' //override in Error.js
          dispatch(transactionsFailure(response.data))
          throw new Error('Error occurred while retrieving transaction history')
        }
      })
      .catch((err) => {
        if (axios.isCancel) {
          dispatch(transactionsFailure(err))
        } else {
          throw err
        }
      })
  }
}
