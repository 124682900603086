export const ACTION_TYPES = {
  PRODUCTS: 'products/PRODUCTS',
  PRODUCTS_REQUEST: 'products/REQUEST',
  PRODUCTS_SUCCESS: 'products/PRODUCTS_SUCCESS',
  PRODUCTS_FAILURE: 'products/PRODUCTS_FAILURE'
}

export const productsRequest = () => {
  return {type: ACTION_TYPES.PRODUCTS_REQUEST}
}

export const productsSuccess = (results) => ({
  type: ACTION_TYPES.PRODUCTS_SUCCESS,
  payload: {results}
})

export const productsFailure = (error) => ({
  type: ACTION_TYPES.PRODUCTS_FAILURE,
  payload: {error}
})
