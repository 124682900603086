import React from 'react'
import {connect} from 'react-redux'
import {
  Grid,
  Responsive,
  Button,
  Header,
  Segment,
  Card,
  Image,
  List,
  Divider,
  Icon,
  Container,
  Confirm
} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import SimpleLoader from '../../components/SimpleLoader'
import PackagePriceTable from '../../components/PackagePriceTable'
import {
  getSubscriptions,
  createSubscriptionAddBaseProduct,
  cancelSubscriptionAutoBill,
  updateSubscriptionAddBaseProduct
} from '../../redux/subscriptions/thunks'
import {getCashBoxAccount} from '../../redux/accounts/thunks'
import {getProductsByID} from '../../redux/products/thunks'
import {makeCurrentSubscriptionState} from '../../redux/subscriptions/selectors'
import {productNames, productDescriptions, productLogos} from '../../utils/configs'
import {importAllImages} from '../../utils/imageHelpers'
import {getCardInfo} from '../../utils/getCardLogo'
import {PageContext} from '../../contexts/pageContext'

const PropertyNameEnum = {
  LOGO: 'LOGO',
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION'
}

class SubscriptionContainer extends React.Component {
  static contextType = PageContext

  constructor(props) {
    super(props)

    this.state = {
      enableContainer: false,
      enableNextButton: false,
      activeProduct: '',
      images: importAllImages(),
      isCancelSubscriptionDialogOpen: false,
      isUpdatingSubscription: false,
      cardActive: null
    }
    this.handleProductClick = this.handleProductClick.bind(this)
    this.onCancelSubscriptionClick = this.onCancelSubscriptionClick.bind(this)
    this.onSubscriptionCancelAbort = this.onSubscriptionCancelAbort.bind(this)
    this.onSubscriptionCancelConfirm = this.onSubscriptionCancelConfirm.bind(this)
    this.onChangeSubscription = this.onChangeSubscription.bind(this)
  }

  componentDidMount() {
    const {getCashBoxAccount, getSubscriptions, getProductsByID} = this.props
    const {isBuyFlowCompletedRedirect} = this.state

    Promise.all([
      getCashBoxAccount(),
      isBuyFlowCompletedRedirect ? Promise.resolve() : getSubscriptions(),
      getProductsByID()
    ]).then(() => {
      this.setState({enableContainer: true})
      this.context.setPageHeader('MY ACCOUNT')
      this.context.setPageSubHeader(this.getSubHeading())
    })
  }

  static getDerivedStateFromProps(props, state) {
    let addedState = {}
    if (props.location.state) {
      addedState.isBuyFlowCompletedRedirect = props.location.state.isBuyFlowCompletedRedirect
      addedState.isUpdatingSubscription = props.location.state.isUpdatingSubscription
    }
    return addedState
  }

  onChangeSubscription = () => {
    const {currentSubscription, products} = this.props

    const baseProduct = currentSubscription.items.data.find((subItem) => {
      return products[subItem.product.id].productType === 'BASE'
    })
    this.setState({
      isUpdatingSubscription: true,
      activeProduct: baseProduct.product.id
    })
  }

  onSubscriptionCancelAbort = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: false
    })
  }

  onSubscriptionCancelConfirm = () => {
    const {cancelSubscriptionAutoBill, currentSubscription, getSubscriptions} = this.props
    this.setState({
      enableContainer: false,
      isCancelSubscriptionDialogOpen: false
    })
    cancelSubscriptionAutoBill(currentSubscription.id).then(() => {
      getSubscriptions().then(() => this.setState({enableContainer: true}))
    })
  }

  onCancelSubscriptionClick = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: true
    })
  }

  handleProductClick = (event, {packageid}) => {
    const {updateSubscriptionAddBaseProduct, createSubscriptionAddBaseProduct, currentSubscription, products} = this.props
    const {isUpdatingSubscription} = this.state

    if (isUpdatingSubscription) {
      const baseProduct = currentSubscription.items.data.find((subItem) => {
        return products[subItem.product.id].productType === 'BASE'
      })
      updateSubscriptionAddBaseProduct(packageid, baseProduct)
    } else {
      createSubscriptionAddBaseProduct(packageid)
    }
    this.setState({enableNextButton: true, activeProduct: packageid})
  }

  getSubHeading = () => {
    const {subscriptions} = this.props
    const {enableContainer} = this.state

    if (!enableContainer) return ''

    return subscriptions.subscriptionModel.total_count === 0 ? 'SELECT A BASE PACKAGE' : 'MANAGE YOUR SUBSCRIPTION'
  }

  getProductInfo = (productType, property, productName) => {
    const {currentSubscription, products} = this.props
    const {images} = this.state

    const activeProduct = currentSubscription.items.data.find((subItem) => {
      return (
        products[subItem.product.id].productType === productType && (productName ? subItem.product.id === productName : true)
      )
    })

    switch (property) {
      case PropertyNameEnum.LOGO:
        return images[productLogos[activeProduct.product.id]]
      case PropertyNameEnum.NAME:
        return productNames[activeProduct.product.id]
      case PropertyNameEnum.DESCRIPTION:
        return productDescriptions[activeProduct.product.id]
      // no default
    }
  }

  getTotalCost = () => {
    const {currentSubscription, products} = this.props
    const totalCostCalculator = (accumulator, currentVal) =>
      accumulator +
      (currentVal.ends
        ? new Date(currentVal.ends.substring(currentVal.ends.indexOf('T'), -1)) > new Date(Date.now())
          ? parseInt(products[currentVal.product.id].price)
          : 0
        : parseInt(products[currentVal.product.id].price))

    return currentSubscription.items.data.reduce(totalCostCalculator, 0)
  }

  getEnhancementsList = () => {
    const {currentSubscription, products} = this.props
    const enhancementProducts = currentSubscription.items.data.flatMap((subItem) => {
      return products[subItem.product.id].productType !== 'ENHANCEMENT'
        ? []
        : !subItem.ends
          ? [subItem]
          : Date.parse(subItem.ends.substring(subItem.ends.indexOf('T'), -1)) > new Date(Date.now())
            ? [subItem]
            : []
    })

    return enhancementProducts.map((e) => {
      return (
        <List.Item key={e.product.id}>
          <List.Icon name="chevron right" />
          <List.Content>
            <List.Header>{this.getProductInfo('ENHANCEMENT', PropertyNameEnum.NAME, e.product.id)}</List.Header>
            <List.Description>
              {this.getProductInfo('ENHANCEMENT', PropertyNameEnum.DESCRIPTION, e.product.id)}
            </List.Description>
          </List.Content>
        </List.Item>
      )
    })
  }

  render() {
    const {subscriptions, products, currentSubscription, cashBoxAccount} = this.props
    const {enableContainer, enableNextButton, isCancelSubscriptionDialogOpen, isUpdatingSubscription} = this.state

    let activeProduct = null
    if (isUpdatingSubscription && currentSubscription) {
      const baseProduct = currentSubscription.items.data.find((subItem) => {
        return products[subItem.product.id].productType === 'BASE'
      })
      activeProduct = baseProduct.product.id
    }

    return (
      <SimpleLoader
        active={!enableContainer || cashBoxAccount.id == null}
        message={!cashBoxAccount.id ? 'Loading Account' : 'Loading Subscription'}
      >
        <Grid divided="vertically" columns="equal" stackable className="condensed">
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={12} computer={9} className="compact">
              {subscriptions.subscriptionModel.total_count === 0 ||
              isUpdatingSubscription ||
              currentSubscription.status === 'Cancelled' ? (
                !enableContainer ? (
                  <Container className="element--full-height" />
                ) : (
                  <PackagePriceTable
                    products={products}
                    activeProduct={this.state.activeProduct}
                    condensed
                    rowColor="darkSlate"
                    handleProductOnClick={this.handleProductClick}
                  />
                )
              ) : (
                <Segment raised>
                  <Grid stackable columns="equal" verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column floated="left" width={8}>
                        <Header as="h4" color={currentSubscription.status === 'Active' ? 'green' : 'black'}>
                          Subscription Status: {currentSubscription.status}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Card fluid>
                          <Card.Content>
                            <Image floated="right" size="mini" src={this.getProductInfo('BASE', PropertyNameEnum.LOGO)} />
                            <Card.Meta>Current Plan</Card.Meta>
                            <Card.Header>{this.getProductInfo('BASE', PropertyNameEnum.NAME)}</Card.Header>
                            <Card.Description>{this.getProductInfo('BASE', PropertyNameEnum.DESCRIPTION)}</Card.Description>
                          </Card.Content>
                          <Card.Content extra>
                            <List>{this.getEnhancementsList()}</List>
                            <Divider horizontal>
                              <Icon name="money bill alternate outline" />
                            </Divider>
                            <Card.Meta>Next Billing</Card.Meta>
                            <Divider hidden />
                            <Card.Header>
                              <Grid className="condensed" columns="equal">
                                <Grid.Row>
                                  <Grid.Column>{'Amount: '}</Grid.Column>
                                  <Grid.Column floated="right">${this.getTotalCost().toFixed(2)}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column>{'Date: '}</Grid.Column>
                                  <Grid.Column floated="right">
                                    {currentSubscription.next_billing
                                      ? new Date(currentSubscription.next_billing.created).toLocaleDateString()
                                      : 'N/A'}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Card.Header>
                            <Divider hidden />
                            <List>
                              <List.Item>
                                <List.Description>
                                  <Segment raised>
                                    {currentSubscription.payment_method.customer_description}:{'  '}
                                    <Icon
                                      name={getCardInfo(currentSubscription.payment_method.credit_card.bin).logo}
                                      size="large"
                                    />
                                    {'\u00A0ending\u00A0in\u00A0'}
                                    {currentSubscription.payment_method.credit_card.last_digits}
                                  </Segment>
                                </List.Description>
                              </List.Item>
                            </List>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                      <Responsive
                        as={Grid.Column}
                        verticalAlign="middle"
                        textAlign="justified"
                        minWidth={Responsive.onlyTablet.minWidth}
                      >
                        <List relaxed>
                          <List.Item className="element--font-size-18">
                            <Link
                              to={{
                                pathname: '/billing/payment',
                                search: '',
                                hash: '',
                                state: {fromSelfCare: true}
                              }}
                            >
                              Update Payment Method
                            </Link>
                          </List.Item>
                          <List.Item as="a" className="element--font-size-18" onClick={this.onChangeSubscription}>
                            Change Subscription
                          </List.Item>
                          <List.Item className="element--font-size-18">
                            <Link
                              to={{
                                pathname: '/billing/transactions',
                                search: '',
                                hash: '',
                                state: undefined
                              }}
                            >
                              Billing Transaction History
                            </Link>
                          </List.Item>
                          <List.Item as="a" className="element--font-size-18" onClick={this.onCancelSubscriptionClick}>
                            Cancel Subscription
                          </List.Item>
                        </List>
                      </Responsive>
                    </Grid.Row>
                  </Grid>
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
          {subscriptions.subscriptionModel.total_count === 0 ||
          currentSubscription.status === 'Cancelled' ||
          isUpdatingSubscription ? (
            <Grid.Row centered>
              <Grid.Column width={3} stretched>
                <Link
                  to={{
                    pathname: 'subscription/products/enhancements',
                    search: '',
                    hash: '',
                    state: {isUpdatingSubscription, currentSubscription}
                  }}
                >
                  <Button
                    fluid
                    content="next"
                    circular
                    className="element--background-color-slate-light"
                    disabled={!enableNextButton && !isUpdatingSubscription}
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
        <Confirm
          open={isCancelSubscriptionDialogOpen}
          onCancel={this.onSubscriptionCancelAbort}
          onConfirm={this.onSubscriptionCancelConfirm}
          content="Are you sure? This action cannot be undone"
          header="Cancel Subscription"
        />
      </SimpleLoader>
    )
  }
}

const makeMapStateToProps = () => {
  const currentSubscription = makeCurrentSubscriptionState()
  return (state, props) => ({
    subscriptions: state.subscriptions,
    cashBoxAccount: state.cashBoxAccount,
    products: state.products,
    currentSubscription: currentSubscription(state.subscriptions.subscriptionModel)
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: () => {
      return dispatch(getSubscriptions())
    },
    createSubscriptionAddBaseProduct: (selectedProduct) => {
      return dispatch(createSubscriptionAddBaseProduct(selectedProduct))
    },
    getCashBoxAccount: () => {
      return dispatch(getCashBoxAccount())
    },
    getProductsByID: () => {
      return dispatch(getProductsByID())
    },
    cancelSubscriptionAutoBill: (subscriptionId) => {
      return dispatch(cancelSubscriptionAutoBill(subscriptionId))
    },
    updateSubscriptionAddBaseProduct: (selectedProduct, currentBaseProduct) => {
      return dispatch(updateSubscriptionAddBaseProduct(selectedProduct, currentBaseProduct))
    }
  }
}

export default connect(
  makeMapStateToProps(),
  mapDispatchToProps
)(SubscriptionContainer)
