import {ACTION_TYPES} from './actions.js'

export default function reducer(state = {total_count: 0}, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, payload)
    case ACTION_TYPES.TRANSACTIONS_FAILURE:
      return Object.assign({}, state, payload.error)
    case ACTION_TYPES.TRANSACTIONS_REQUEST:
      return Object.assign({}, {cancelRequest: payload})
    default:
      return state
  }
}
