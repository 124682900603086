import {createSelector} from 'reselect'

const findActiveSubscription = (state) => {
  return state.data ? state.data.find((sub) => sub.status === 'Active' || sub.status === 'Processing') : undefined
}

const findCancelPendingSubscription = (state) => {
  if (!state.data) return undefined
  let pendingCancel = {}
  const pendingCancellations = state.data.filter((sub) => sub.status === 'Pending Cancel')

  if (pendingCancellations.length > 1) {
    pendingCancel = pendingCancellations.reduce((max, sub) => (Date.parse(sub.start) > Date.parse(max.start) ? sub : max))
  } else {
    pendingCancel = pendingCancellations.shift()
  }

  return pendingCancel
}

const getMostRecentSubscription = (state) => {
  if (!state.data || state.data.length === 0) return undefined

  return state.data.reduce((max, sub) => (Date.parse(sub.start) > Date.parse(max.start) ? sub : max))
}

export const makeCurrentSubscriptionState = () =>
  createSelector(
    findActiveSubscription,
    findCancelPendingSubscription,
    getMostRecentSubscription,
    (active, cancelled, mostRecent) => {
      return active || cancelled || mostRecent
    }
  )
