import {combineReducers} from 'redux'

import products from './products/reducers.js'
import subscriptions from './subscriptions/reducers.js'
import transactions from './transactions/reducers.js'
import webSession from './payment/reducers.js'
import cashBox from './cashBox/reducers.js'
import cashBoxAccount from './accounts/reducers'

export default combineReducers({
  products,
  subscriptions,
  transactions,
  webSession,
  cashBox,
  cashBoxAccount
})
