import {Dimmer, Container, Icon, Image, Header, Button, Segment} from 'semantic-ui-react'
import React from 'react'
import PropTypes from 'prop-types'
import withAPISecurity from '../hocs/withAPISecurity'
import scenicBackground from '../assets/images/scenic-background.jpg'
import {UserContext} from '../contexts/userContext'

const APISecureLoginButton = withAPISecurity(Button)

class HomepageHeading extends React.Component {
  static contextType = UserContext

  invokeSignUp = () => {
    this.context.authService.login(true)
  }

  render() {
    const {mobile} = this.props

    return (
      <Dimmer.Dimmable as={(props) => <Segment attached>{props.children}</Segment>} dimmed={true}>
        <p>
          <Image src={scenicBackground} fluid style={{height: 500, paddingTop: 10}} />
        </p>
        <Dimmer active={true}>
          <Container text>
            <Header
              as="h1"
              content="Imagine-a-Company"
              inverted
              style={{
                fontSize: mobile ? '2em' : '4em',
                fontWeight: 'normal',
                marginBottom: '1em'
              }}
            />
            <Header
              as="h2"
              content="Do whatever you want when you want to."
              inverted
              style={{
                fontSize: mobile ? '1.5em' : '1.7em',
                fontWeight: 'normal',
                marginTop: mobile ? '0.5em' : '1.5em'
              }}
            />
            <APISecureLoginButton as="a" onAPIUnlocked={this.invokeSignUp} primary size="huge">
              Get Started
              <Icon name="right arrow" />
            </APISecureLoginButton>
          </Container>
        </Dimmer>
      </Dimmer.Dimmable>
    )
  }
}

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
}

export default HomepageHeading
