export const ACTION_TYPES = {
  WEB_SESSION_INITIALIZE_REQUEST: 'payments/web_session/initialize/REQUEST',
  WEB_SESSION_INITIALIZE_SUCCESS: 'payments/web_session/initialize/SUCCESS',
  WEB_SESSION_INITIALIZE_FAILURE: 'payments/web_session/initialize/FAILURE',
  WEB_SESSION_FINALIZE_REQUEST: 'payments/web_session/finalize/REQUEST',
  WEB_SESSION_FINALIZE_SUCCESS: 'payments/web_session/finalize/SUCCESS',
  WEB_SESSION_FINALIZE_FAILURE: 'payments/web_session/finalize/FAILURE',
  WEB_SESSION_FINALIZE_RESET: 'payments/web_session/finalize/RESET'
}

export const webSessionInitializePaymentRequest = () => {
  return {type: ACTION_TYPES.WEB_SESSION_INITIALIZE_REQUEST}
}

export const webSessionInitializePaymentSuccess = (results) => ({
  type: ACTION_TYPES.WEB_SESSION_INITIALIZE_SUCCESS,
  payload: results
})

export const webSessionInitializePaymentFailure = (error) => ({
  type: ACTION_TYPES.WEB_SESSION_INITIALIZE_FAILURE,
  payload: error
})

export const webSessionFinalizePaymentRequest = () => {
  return {type: ACTION_TYPES.WEB_SESSION_FINALIZE_REQUEST}
}

export const webSessionFinalizePaymentSuccess = (results) => ({
  type: ACTION_TYPES.WEB_SESSION_FINALIZE_SUCCESS,
  payload: results
})

export const webSessionFinalizePaymentFailure = (error) => ({
  type: ACTION_TYPES.WEB_SESSION_FINALIZE_FAILURE,
  payload: error
})

export const webSessionFinalizePaymentReset = () => {
  return {type: ACTION_TYPES.WEB_SESSION_FINALIZE_RESET}
}
