import {ACTION_TYPES} from './actions.js'
import {combineReducers} from 'redux'
import uuidv4 from 'uuid/v4'

const emptyCreateSubscriptionModel = () => ({
  object: 'Subscription',
  id: uuidv4(),
  account: {object: 'Account'},
  billing_plan: {object: 'BillingPlan'},
  payment_method: {object: 'PaymentMethod'},
  currency: 'USD',
  description: '',
  starts: '',
  items: [],
  minimum_commitment: '0',
  policy: {
    ignore_cvn_policy: 1,
    ignore_avs_policy: 1,
    min_chargeback_probability: 99,
    immediate_auth_failure_policy: 'doNotSaveAutoBill',
    validate_for_future_payment: 0
  }
})

const initialUpdateSubscriptionModel = () => ({
  object: 'Subscription',
  id: null,
  billing_plan: {object: 'BillingPlan'},
  items: []
})

const subscriptionItemTemplate = () => ({
  object: 'SubscriptionItem',
  id: uuidv4(),
  product: {
    object: 'Product'
  }
})

const removeSubscriptionItemTemplate = () => ({
  object: 'SubscriptionItem',
  replaces: null
})

function updateSubscriptionReducer(state = initialUpdateSubscriptionModel(), {type, payload}) {
  let newState = Object.assign({}, state)

  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_ENHANCEMENT_PRODUCT: {
      const existsInCurrentSubscriptionIndex = newState.items
        ? newState.items.findIndex(
            (item) =>
              (payload.subscriptionItem && item.replaces === payload.subscriptionItem.id) ||
              (item.product && item.product.id === payload.selectedProductId)
          )
        : -1
      if (existsInCurrentSubscriptionIndex > -1) {
        newState.items.splice(existsInCurrentSubscriptionIndex, 1)
      } else {
        const subItem = subscriptionItemTemplate()
        subItem.product.id = payload.selectedProductId
        newState.items.push(subItem)
      }
      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_REMOVE_ENHANCEMENT_PRODUCT: {
      const newItemIndex = newState.items.indexOf((item) => item.product.id === payload.selectedProductId)
      if (newItemIndex > -1) {
        newState.items.splice(newItemIndex, 1)
      } else {
        const subItem = removeSubscriptionItemTemplate()
        subItem.replaces = payload.subscriptionItem.id
        newState.items.push(subItem)
      }
      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_BASE_PRODUCT: {
      if (payload.selectedProductId !== payload.currentBaseSubscriptionItem.product.id) {
        const subItem = subscriptionItemTemplate()
        subItem.product.id = payload.selectedProductId
        subItem.replaces = payload.currentBaseSubscriptionItem.id
        newState.items.push(subItem)
      } else {
        newState.items = []
      }

      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS:
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST: {
      return initialUpdateSubscriptionModel()
    }
    default:
      return state
  }
}

const initialState = () => ({total_count: 0, data: [], url: '', next: '', previous: '', object: 'List'})
function subscriptionReducer(state = initialState(), {type, payload}) {
  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_SUCCESS: {
      return Object.assign({}, state, payload)
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_AUTO_BILL:
      return Object.assign({}, state, payload)
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_AUTO_BILL: {
      const newState = Object.assign({}, initialState())
      newState.data.push(payload)
      newState.total_count = newState.data.length

      const baseUrl = `/subscriptions?account=${encodeURI(payload.account.id)}`
      newState.url = baseUrl
      newState.next = `baseUrl&starting_after=${encodeURI(payload.id)}`
      newState.previous = `baseURL&ending_before=${encodeURI(payload.id)}`
      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST:
      return initialState()
    default:
      return state
  }
}

function createSubscriptionReducer(state = emptyCreateSubscriptionModel(), {type, payload}) {
  let newState = Object.assign({}, state)
  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_PAYMENT_METHOD: {
      newState.payment_method.id = payload
      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_BASE_PRODUCT: {
      if (newState.items.length === 0) {
        newState = Object.assign(
          newState,
          {account: {object: 'Account', id: payload.accountId}},
          {
            items: []
          }
        )
      } else {
        const itemIndex = newState.items.findIndex(
          (subItem) => payload.products[subItem.product.id] && payload.products[subItem.product.id].productType === 'BASE'
        )
        newState.items.splice(itemIndex, 1)
      }
      const subItem = subscriptionItemTemplate()
      subItem.product.id = payload.selectedProductId
      newState.items.push(subItem)

      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_ENHANCEMENT_PRODUCT: {
      const subItem = subscriptionItemTemplate()
      subItem.product.id = payload.selectedProductId
      newState.items.push(subItem)

      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_REMOVE_ENHANCEMENT_PRODUCT: {
      const itemIndex = newState.items.findIndex(
        (subItem) =>
          payload.products[subItem.product.id] && payload.products[subItem.product.id].productType === 'ENHANCEMENT'
      )
      if (itemIndex > -1) {
        newState.items.splice(itemIndex, 1)
      }

      return newState
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS:
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST: {
      return emptyCreateSubscriptionModel()
    }
    default:
      return state
  }
}

export default combineReducers({
  subscriptionModel: subscriptionReducer,
  createSubscriptionModel: createSubscriptionReducer,
  updateSubscriptionModel: updateSubscriptionReducer
})
