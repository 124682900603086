import React from 'react'
import {connect} from 'react-redux'
import {Grid, Button, List, Label} from 'semantic-ui-react'
import {getEnchancementProducts} from '../../redux/products/selectors'
import {
  createSubscriptionAddEnhancements,
  createSubscriptionRemoveEnhancements,
  updateSubscriptionAddEnhancements,
  updateSubscriptionRemoveEnhancements
} from '../../redux/subscriptions/thunks'
import {Link} from 'react-router-dom'
import {productNames, productDescriptions} from '../../utils/configs'
import history from '../../history'
import {PageContext} from '../../contexts/pageContext'

class EnhancementsContainer extends React.Component {
  static contextType = PageContext

  constructor(props) {
    super(props)
    this.handleEnhancementsClick = this.handleEnhancementsClick.bind(this)
    this.buttonRefs = {}
    this.state = Object.assign({}, this.props.location.state)
  }

  componentDidMount() {
    const {subscription} = this.props
    const {isUpdatingSubscription} = this.state
    if (
      subscription.createSubscriptionModel &&
      subscription.createSubscriptionModel.items.length === 0 &&
      !isUpdatingSubscription
    ) {
      history.replace('/subscription')
    }
    this.context.setPageHeader('MY ACCOUNT')
    this.context.setPageSubHeader('SELECT ADD ONS')
  }

  getBackgroundColor = (control) => {
    let className = ''
    if (this.props.rowColor === 'darkSlate') {
      switch (control.ref.nodeName) {
        case 'BUTTON':
        case 'LABEL':
          className = 'element--background-color-grey'
          break
        default:
          className = 'element--background-color-slate-dark'
          break
      }
    }
    return className
  }

  handleEnhancementsClick = (event, {className}) => {
    const {
      createSubscriptionAddEnhancement,
      createSubscriptionRemoveEnhancement,
      updateSubscriptionAddEnhancement,
      updateSubscriptionRemoveEnhancement,
      subscription: {updateSubscriptionModel}
    } = this.props
    const {isUpdatingSubscription, currentSubscription} = this.state
    let refs = this.buttonRefs

    const subItem = !isUpdatingSubscription
      ? undefined
      : currentSubscription.items.data.find((s) => s.product.id === className && !s.ends) ||
        (updateSubscriptionModel.items &&
          updateSubscriptionModel.items.find((s) => !s.replaces && s.product.id === className))

    if (refs[className].ref.textContent === 'add') {
      isUpdatingSubscription
        ? updateSubscriptionAddEnhancement(subItem, className)
        : createSubscriptionAddEnhancement(className)
      refs[className].ref.textContent = 'remove'
    } else {
      isUpdatingSubscription
        ? updateSubscriptionRemoveEnhancement(subItem, className)
        : createSubscriptionRemoveEnhancement(className)
      refs[className].ref.textContent = 'add'
    }
  }

  getButtonText = (productId) => {
    const {isUpdatingSubscription, currentSubscription} = this.state
    const {products} = this.props

    if (!isUpdatingSubscription) return 'add'

    const enhancementProducts = currentSubscription.items.data.filter((subItem) => {
      return products[subItem.product.id].productType === 'ENHANCEMENT'
    })

    const isIncluded = enhancementProducts
      .flatMap((subItem) => {
        return subItem.product.id !== productId
          ? []
          : !subItem.ends
            ? [subItem]
            : new Date(subItem.ends.substring(subItem.ends.indexOf('T', -1))) > new Date(Date.now())
              ? [subItem]
              : []
      })
      .shift()
    return isUpdatingSubscription && isIncluded ? 'remove' : 'add'
  }

  getEnhancements = () => {
    const {enhancementProducts, products} = this.props
    if (enhancementProducts.length === 0) return
    return enhancementProducts.map((p) => {
      return (
        <List.Item key={`${p}ListItem`}>
          <List.Header>{productNames[p]}</List.Header>
          <List.Content floated="right">
            <Button
              content={this.getButtonText(p)}
              className={`${p}`}
              ref={(el) => (this.buttonRefs[`${p}`] = el)}
              onClick={this.handleEnhancementsClick}
            />
          </List.Content>
          <List.Content floated="left">
            <Label color="blue" tag>
              ${products[p].price}
            </Label>
          </List.Content>
          <List.Content>{productDescriptions[p]}</List.Content>
        </List.Item>
      )
    })
  }

  render() {
    const {isUpdatingSubscription, currentSubscription} = this.state
    return (
      <Grid
        divided="vertically"
        stackable
        columns={16}
        padded
        className="condensed element--padding-top-10"
        textAlign="center"
      >
        <Grid.Row>
          <Grid.Column textAlign="left" width={7}>
            <List divided verticalAlign="middle">
              {this.getEnhancements()}
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={5} stretched>
            <Link
              to={{
                pathname: isUpdatingSubscription ? '/subscription/billing/summary' : '/subscription/billing/payment',
                search: '',
                hash: '',
                state: {isUpdatingSubscription, currentSubscription}
              }}
            >
              <Button fluid content="next" circular className="element--background-color-slate-light" />
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = (store, props) => ({
  products: store.products,
  subscription: store.subscriptions,
  enhancementProducts: getEnchancementProducts(store)
})

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscriptionAddEnhancement: (selectedProduct) => {
      return dispatch(createSubscriptionAddEnhancements(selectedProduct))
    },
    createSubscriptionRemoveEnhancement: (selectedProduct) => {
      return dispatch(createSubscriptionRemoveEnhancements(selectedProduct))
    },
    updateSubscriptionAddEnhancement: (selectedProduct, selectedProductId) => {
      return dispatch(updateSubscriptionAddEnhancements(selectedProduct, selectedProductId))
    },
    updateSubscriptionRemoveEnhancement: (selectedProduct, selectedProductId) => {
      return dispatch(updateSubscriptionRemoveEnhancements(selectedProduct, selectedProductId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancementsContainer)
