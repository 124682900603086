export const STATUS_TYPES = {
  UNLOAD_STATE: 'notLoaded',
  INIT_STATE: 'initState',
  SUCCEED_STATE: 'succeedState',
  FAILED_STATE: 'failedState'
}

export const BROWSER = {
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  IE: 'IE',
  EDGE: 'Edge',
  CHROME: 'Chrome'
}

export const allowLanguages = ['en', 'es', 'pt']

export const LANGUAGES = {
  DEFAULT_LANGUAGE: 'es',
  ENGLISH_LANGUAGE: 'en',
  SPANISH_LANGUAGE: 'es',
  PORTUGUESE_LANGUAGE: 'pt'
}

export const COUNTRIES = {
  DEFAULT_COUNTRY: 'ar'
}

export const COUNTRIES_CURRENCIES = {
  ar: 'ARS',
  br: 'BRL',
  cl: 'CLP',
  co: 'COP',
  ec: 'ECS',
  pe: 'PEN',
  uy: 'UYI',
  us: 'USD'
}

export const CURRENCIES = {
  // must match values used in cashbox ARS, BRL, CLP, COP, ECS, PEN, UYI, USD
  ARS: {symbol: '$', country: 'ar'},
  BRL: {symbol: 'R$', country: 'br'},
  CLP: {symbol: '$', country: 'cl'},
  COP: {symbol: '$', country: 'co'},
  ECS: {symbol: '$', country: 'ec'},
  PEN: {symbol: 'S/', country: 'pe'},
  UYI: {symbol: '$U', country: 'uy'},
  USD: {symbol: '$', country: 'us'}
}

export const CARDLOGO = {
  // must match values used in import creditCardType from 'credit-card-type';
  visa: 'cc visa',
  mastercard: 'cc mastercard',
  amex: 'cc amex',
  'american-express': 'cc amex',
  'diners-club': 'cc diners club',
  discover: 'cc discover',
  jcb: 'cc jcb',
  unionpay: 'credit card outline',
  maestro: 'credit card outline',
  generic: 'credit card outline',
  dankort: 'credit card outline',
  diners_club_carte_blanche: 'cc diners club',
  diners_club_international: 'cc diners club',
  visa_electron: 'cc visa'
}

// Country   Country  Currency  Language
// USA       US       USD       EN
// Argentina AR       ARS       ES
// Brazil    BR       BRL       PT
// Chile     CL       CLP       ES
// Colombia  CO       COP       ES
// Ecuador   EC       ECS       ES
// Peru      PE       PEN       ES
// Uruguay   UY       UYI       ES
// EN-> English
// ES-> Español
// PT-> Português
