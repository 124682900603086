import React from 'react'
import {Modal, Input, Message} from 'semantic-ui-react'
import {UserContext} from '../contexts/userContext'

const withAPISecurity = (WrappedComponent) => {
  return class extends React.Component {
    static contextType = UserContext

    constructor(props) {
      super(props)
      this.state = {
        hasAPIError: false,
        loading: false,
        isModalOpen: false,
        errorMessage: '',
        loginType: null
      }

      this.handleAPIUnlock = this.handleAPIUnlock.bind(this)
      this.handleOnClick = this.handleOnClick.bind(this)
    }

    componentDidUpdate() {
      if (this.inputRef) {
        this.inputRef.focus()
      }
    }

    componentWillUnmount() {
      if (this.inputRef && this.inputRef.inputRef) {
        this.inputRef.inputRef.removeEventListener('keydown', this.captureKeyDown)
      }
    }

    handleRef = (c) => {
      this.inputRef = c
      if (c && c.inputRef) {
        c.inputRef.addEventListener('keydown', this.captureKeyDown)
      }
    }

    captureKeyDown = (e) => {
      if (e.code === 'Enter') {
        this.handleAPIUnlock(e, {})
      }
    }

    handleOnClick = (event, {name}) => {
      this.setState({isModalOpen: true, trigger: name})
    }

    handleAPIUnlock = (event, data) => {
      const {onAPIUnlocked} = this.props
      const {trigger} = this.state

      this.setState({loading: true})

      return this.context.authService
        .unlockAPI(this.inputRef.inputRef.value)
        .then((response) => {
          this.setState({loading: false, hasAPIError: false, isModalOpen: false})
        })
        .then(() => {
          if (onAPIUnlocked && typeof onAPIUnlocked === 'function') {
            onAPIUnlocked(event, Object.assign({trigger}, data))
          }
        })
        .catch((err) => {
          if (err.response.status === 502) {
            // This is the error code returned from the API gateway/Lambda integration
            this.setState({
              loading: false,
              hasAPIError: true,
              errorMessage: 'Invalid API key.  Please check your inputs and try again'
            })
          } else {
            this.setState({
              loading: false,
              hasAPIError: true,
              errorMessage: err.response.data.message
            })
          }
        })
    }

    getMessageProps = () => ({
      hidden: !this.state.hasAPIError,
      visible: this.state.hasAPIError
    })

    onInputValueChange = (event, data) => {
      const myValue = data
      myValue.toString()
    }

    onModalClose = (event, data) => {
      this.setState({isModalOpen: false})
    }

    render() {
      const {isModalOpen, loading, errorMessage} = this.state
      const {isAPISecured = true, onAPIUnlocked, ...rest} = this.props

      return (
        <>
          <Modal open={isModalOpen && isAPISecured} closeOnDimmerClick={false} closeIcon onClose={this.onModalClose}>
            <Modal.Header>Unlock API</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                Please enter the password to unlock the StoreFront API
                <Input
                  placeholder="api password"
                  action={{
                    color: 'grey',
                    content: 'unlock',
                    onClick: this.handleAPIUnlock,
                    loading: loading
                  }}
                  onChange={this.onInputValueChange}
                  ref={this.handleRef}
                  fluid
                />
                <Message negative {...this.getMessageProps()}>
                  <Message.Header>Error Unlocking API</Message.Header>
                  {errorMessage}
                </Message>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <WrappedComponent {...rest} onClick={this.handleOnClick} />
        </>
      )
    }
  }
}

export default withAPISecurity
