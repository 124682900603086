import React from 'react'
import DesktopLayout from './DesktopLayout'
import MobileLayout from './MobileLayout'
import {PageContext} from '../contexts/pageContext'

class ResponsiveLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageHeader: '',
      pageSubHeader: '',
      setPageHeader: this.setPageHeader,
      setPageSubHeader: this.setPageSubHeader
    }

    this.setPageHeader = this.setPageHeader.bind(this)
    this.setPageSubHeader = this.setPageSubHeader.bind(this)
  }

  setPageSubHeader = (newValue) => {
    this.setState({pageSubHeader: newValue})
  }

  setPageHeader = (newValue) => {
    this.setState({pageHeader: newValue})
  }

  render() {
    const {showHomepageHeading, disabled, children} = this.props
    return (
      <PageContext.Provider value={this.state}>
        <DesktopLayout showHomepageHeading={!!showHomepageHeading} disabled={!!disabled}>
          {children}
        </DesktopLayout>
        <MobileLayout showHomepageHeading={!!showHomepageHeading}>{children}</MobileLayout>
      </PageContext.Provider>
    )
  }
}

export default ResponsiveLayout
