import creditCardType, {getTypeInfo} from 'credit-card-type'
import {CARDLOGO} from './constants.js'

export function getCardInfo(bin) {
  const cardMatches = creditCardType(bin).map(function(card) {
    card.logo = CARDLOGO[card.type] || 'credit card outline'
    return card
  })

  if (cardMatches && cardMatches.length > 0) {
    // the algorithm is stacked to favor major card networks, in
    // case there are duplicate matches, pick the highest
    // ranked card network
    return cardMatches[0]
  }

  return {niceType: 'default', logo: 'credit card outline'}
}

export function prettyCardNumber(cardNumber, accountLength, cardType, maskChar = 'X') {
  cardNumber = cardNumber.padStart(accountLength, maskChar)
  var card = getTypeInfo(cardType)
  if (card) {
    var offsets = [].concat(0, card.gaps, cardNumber.length)
    var components = []
    for (var i = 0; offsets[i] < cardNumber.length; i++) {
      var start = offsets[i]
      var end = Math.min(offsets[i + 1], cardNumber.length)
      components.push(cardNumber.substring(start, end))
    }
    return components.join(' ')
  }
  return cardNumber
}
