import React from 'react'
import DesktopMarketingContainer from './DesktopMarketingContainer'
import MobileMarketingContainer from './MobileMarketingContainer'

class ResponsiveMarketingContainer extends React.Component {
  render() {
    const {showHomepageHeading, disabled, children} = this.props
    return (
      <>
        <DesktopMarketingContainer showHomepageHeading={!!showHomepageHeading} disabled={!!disabled}>
          {children}
        </DesktopMarketingContainer>
        <MobileMarketingContainer showHomepageHeading={!!showHomepageHeading}>{children}</MobileMarketingContainer>
      </>
    )
  }
}

export default ResponsiveMarketingContainer
