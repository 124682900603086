import React from 'react'
import {connect} from 'react-redux'
import {Grid, Input, Button, Segment, Form, Label, Icon, Card, Modal, TextArea, Checkbox} from 'semantic-ui-react'
import {createSubscriptionAutoBill, updateSubscriptionAutoBill} from '../../redux/subscriptions/thunks'
import {getCardInfo, prettyCardNumber} from '../../utils/getCardLogo'
import history from '../../history'
import {productNames, productDescriptions} from '../../utils/configs'
import SimpleLoader from '../../components/SimpleLoader'
import {Redirect} from 'react-router-dom'
import termsAndConditionsText from '../../assets/legal/termsAndConditions.txt'
import {PageContext} from '../../contexts/pageContext'

class SummaryContainer extends React.Component {
  static contextType = PageContext

  constructor(props) {
    super(props)
    this.state = {
      agreedToTermsAndConditions: false,
      submitBlocked: true,
      loaderActive: false,
      loaderMessage: '',
      redirectToSubscription: false
    }
    Object.assign(this.state, this.props.location.state)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
  }

  componentDidMount() {
    this.context.setPageSubHeader('CONFIRM MY SUBSCRIPTION')
  }

  static getDerivedStateFromProps(props, state) {
    const {createSubscriptionModel, updateSubscriptionModel} = props
    const {isUpdatingSubscription, redirectToSubscription} = state

    return {
      redirectToSubscription: redirectToSubscription
        ? redirectToSubscription
        : (createSubscriptionModel.items.length === 0 && !isUpdatingSubscription) ||
          (updateSubscriptionModel.items.length === 0 && isUpdatingSubscription)
    }
  }

  handleTermsAndConditionsAgreement = (event, {checked}) => {
    this.setState({agreedToTermsAndConditions: checked})
  }

  handleSubmitClick = (event, data) => {
    const {createSubscription, updateSubscription, createSubscriptionModel, updateSubscriptionModel} = this.props
    const {agreedToTermsAndConditions, isUpdatingSubscription, currentSubscription} = this.state

    if (!agreedToTermsAndConditions) {
      return
    }

    if (!isUpdatingSubscription) {
      createSubscriptionModel.billing_plan.id = '14D_FREE_TRIAL_MONTHLY_BILL'
      createSubscriptionModel.starts = new Date().toISOString()
      this.setState({loaderActive: true, loaderMessage: 'Creating Subscription'}, () => {
        createSubscription(createSubscriptionModel).then(() => {
          history.replace('/subscription', {isBuyFlowCompletedRedirect: true})
        })
      })
    } else {
      updateSubscriptionModel.billing_plan.id = '14D_FREE_TRIAL_MONTHLY_BILL'
      updateSubscriptionModel.id = currentSubscription.id
      this.setState({loaderActive: true, loaderMessage: 'Updating Subscription'}, () => {
        updateSubscription(updateSubscriptionModel).then(() => {
          history.replace('/subscription')
        })
      })
    }
  }

  handleCancelClick = () => {
    this.setState({redirectToSubscription: true})
  }

  buildSummaryModel = () => {
    const {products, updateSubscriptionModel, paymentMethod, createSubscriptionModel} = this.props

    const {currentSubscription, isUpdatingSubscription} = this.state
    let baseProduct, enhancementProducts, selectedPaymentMethod

    selectedPaymentMethod = paymentMethod || currentSubscription.payment_method

    if (!isUpdatingSubscription) {
      const newBaseProduct = createSubscriptionModel.items.find((i) => products[i.product.id].productType === 'BASE')
      const baseProductId = newBaseProduct.product.id
      baseProduct = products[baseProductId]
      baseProduct.name = productNames[baseProductId]
      baseProduct.description = productDescriptions[baseProductId]

      enhancementProducts = createSubscriptionModel.items.flatMap(
        (item) =>
          products[item.product.id].productType !== 'ENHANCEMENT'
            ? []
            : [
                {
                  ...products[item.product.id],
                  name: productNames[item.product.id],
                  description: productDescriptions[item.product.id]
                }
              ]
      )
    } else {
      const baseSubscriptionItem =
        updateSubscriptionModel.items
          .flatMap(
            (item) => (item.replaces && !item.product ? [] : products[item.product.id].productType !== 'BASE' ? [] : item)
          )
          .pop() || currentSubscription.items.data.find((item) => products[item.product.id].productType === 'BASE')

      // Populate base product info for view model
      const baseProductId = baseSubscriptionItem.product.id
      baseProduct = {
        ...products[baseProductId],
        name: productNames[baseProductId],
        description: productDescriptions[baseProductId]
      }

      // Get the newly added enhancement products
      const newEnhancementProducts = updateSubscriptionModel.items.flatMap(
        (en) =>
          en.replaces
            ? []
            : products[en.product.id].productType === 'BASE'
              ? []
              : [
                  {
                    ...products[en.product.id],
                    name: productNames[en.product.id],
                    description: productDescriptions[en.product.id]
                  }
                ]
      )

      // Determine which enhancement products are being removed from the subscription and which ones remain, if any
      const groomedEnhancementProducts = currentSubscription.items.data.flatMap(
        (item) =>
          products[item.product.id].productType === 'BASE'
            ? []
            : updateSubscriptionModel.items.some((upd) => upd.replaces && upd.replaces === item.id)
              ? []
              : item.ends && new Date(item.ends.substring(item.ends.indexOf('T'), -1)) <= new Date(Date.now())
                ? []
                : [
                    {
                      ...products[item.product.id],
                      name: productNames[item.product.id],
                      description: productDescriptions[item.product.id]
                    }
                  ]
      )

      enhancementProducts = [...newEnhancementProducts, ...groomedEnhancementProducts]
    }
    return {
      selectedPaymentMethod,
      baseProduct,
      enhancementProducts
    }
  }

  getTotalCost = (subscriptionProducts) => {
    const totalCostCalculator = (accumulator, currentVal) => accumulator + parseInt(currentVal.price)
    return subscriptionProducts.reduce(totalCostCalculator, 0)
  }

  toggleTermsAndConditions = () => {
    this.setState({
      showingTermsAndConditions: !this.state.showingTermsAndConditions
    })
  }

  termsAndConditionsModal = () => {
    const {agreedToTermsAndConditions, showingTermsAndConditions} = this.state
    return (
      <Modal
        onClose={this.toggleTermsAndConditions}
        closeIcon={<Icon name="close" />}
        closeOnDimmerClick
        open={showingTermsAndConditions}
        trigger={
          <Checkbox
            onChange={this.handleTermsAndConditionsAgreement}
            checked={agreedToTermsAndConditions}
            label={
              <label>
                I agree to the{' '}
                <a href="#t-and-c" onClick={this.toggleTermsAndConditions}>
                  terms and conditions
                </a>
              </label>
            }
          />
        }
      >
        <Modal.Header>Terms And Conditions</Modal.Header>
        <Modal.Content>
          <Segment raised>
            <Form>
              <Form.TextArea readOnly rows={25} value={termsAndConditionsText} />
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    const {loaderActive, loaderMessage, agreedToTermsAndConditions, redirectToSubscription} = this.state
    if (redirectToSubscription) {
      return <Redirect to="/subscription" />
    }
    const {selectedPaymentMethod: paymentMethod, baseProduct, enhancementProducts} = this.buildSummaryModel()
    const termsAndConditionsModal = this.termsAndConditionsModal()
    return (
      <SimpleLoader active={loaderActive} message={loaderMessage}>
        <Grid divided="vertically" stackable columns={16} className="condensed" textAlign="center">
          <Grid.Row>
            <Grid.Column width={9}>
              <Segment>
                <Form>
                  <Segment raised>
                    <Label attached="top left">Payment Method</Label>
                    <Grid columns={2} textAlign="justified">
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Account Name</label>
                            <Input
                              placeholder="Account alias"
                              readOnly
                              transparent
                              value={paymentMethod.customer_description}
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Billing Address</label>
                            <Input
                              placeholder="Street Address"
                              transparent
                              readOnly
                              value={paymentMethod.billing_address.line1}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Card #</label>
                            <Icon name={getCardInfo(paymentMethod.credit_card.bin).logo} size="large" />
                            <Input
                              transparent
                              placeholder="credit card number"
                              value={prettyCardNumber(
                                paymentMethod.credit_card.last_digits,
                                paymentMethod.credit_card.account.length,
                                getCardInfo(paymentMethod.credit_card.bin).type,
                                'x'
                              )}
                              readOnly
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Billing City, State</label>
                            <Input
                              transparent
                              placeholder="city, state"
                              readOnly
                              value={`${paymentMethod.billing_address.city}, ${paymentMethod.billing_address.district}`}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Name On Card</label>
                            <Input
                              transparent
                              placeholder="Name as it appears on card"
                              readOnly
                              value={paymentMethod.account_holder}
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field inline>
                            <label>Billing Zip Code</label>
                            <Input
                              transparent
                              placeholder="zip code"
                              readOnly
                              value={paymentMethod.billing_address.postal_code}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  <Segment raised padded>
                    <Label attached="top left">Subscription Summary</Label>
                    {baseProduct ? (
                      <Card.Group itemsPerRow={3} centered>
                        <Card fluid>
                          <Card.Content header={baseProduct.name} />
                          <Card.Content description={baseProduct.description} />
                          <Card.Content>Price: ${baseProduct.price}</Card.Content>
                          {/* <Card.Content>Taxes: 10%</Card.Content> */}
                          <Card.Content extra>Base Package</Card.Content>
                        </Card>
                        {enhancementProducts.map((e) => (
                          <Card key={e.name}>
                            <Card.Content key={`${e.name}-content`} header={e.name} />
                            <Card.Content key={`${e.name}-description`} description={e.description} />
                            <Card.Content key={`${e.name}-extra`}>Price: ${e.price}</Card.Content>
                            <Card.Content key={`${e.name}-meta`} extra>
                              Add-On
                            </Card.Content>
                          </Card>
                        ))}
                      </Card.Group>
                    ) : null}
                    <Card fluid>
                      <Card.Content>
                        Total Monthly Billing Amount: $
                        {/* {this.getTotalCost([baseProduct, ...enhancementProducts]) + baseProduct.price * 0.1} */}
                        {this.getTotalCost([baseProduct, ...enhancementProducts])}
                      </Card.Content>
                    </Card>
                  </Segment>
                  <Form.Field>{termsAndConditionsModal}</Form.Field>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Button
                content="submit"
                circular
                className="element--background-color-slate-light"
                onClick={this.handleSubmitClick}
                {...{disabled: !agreedToTermsAndConditions}}
              />
              <Button
                content="cancel"
                circular
                className="element--background-color-grey"
                onClick={this.handleCancelClick}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SimpleLoader>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    createSubscriptionModel: state.subscriptions.createSubscriptionModel,
    updateSubscriptionModel: state.subscriptions.updateSubscriptionModel,
    products: state.products,
    paymentMethod: state.webSession.webSessionFinalizePayment.paymentMethod
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscription: (subscriptionObject) => {
      return dispatch(createSubscriptionAutoBill(subscriptionObject))
    },
    updateSubscription: (subscriptionObject) => {
      return dispatch(updateSubscriptionAutoBill(subscriptionObject))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryContainer)
