import React from 'react'
import {Segment, Dimmer, Loader} from 'semantic-ui-react'

class Callback extends React.Component {
  render() {
    return (
      <Segment className="homepage homepage-callback">
        <Dimmer active>
          <Loader size="massive" indeterminate active>
            Preparing Session
          </Loader>
        </Dimmer>
      </Segment>
    )
  }
}

export default Callback
