export const ACTION_TYPES = {
  CASH_BOX_REQUEST: 'CASH_BOX/REQUEST',
  CASH_BOX_SUCCESS: 'CASH_BOX/SUCCESS',
  CASH_BOX_FAILURE: 'CASH_BOX/FAILURE'
}

export const cashBoxRequest = () => {
  return {type: ACTION_TYPES.CASH_BOX_REQUEST}
}

export const cashBoxSuccess = (results) => ({
  type: ACTION_TYPES.CASH_BOX_SUCCESS,
  payload: results
})

export const cashBoxFailure = (error) => ({
  type: ACTION_TYPES.CASH_BOX_FAILURE,
  payload: error
})
