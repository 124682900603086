import React from 'react'
import {connect} from 'react-redux'
import {Button, Form, Icon, Grid, Message, Label, Confirm} from 'semantic-ui-react'
import cn from 'classnames'
import {getWebSessionInitializePayment, getWebSessionFinalizePayment} from '../../redux/payment/thunks'
import {PageContext} from '../../contexts/pageContext'
import history from '../../history'

const intialState = {
  cardNumber: {isValid: true, hasData: false},
  expirationDate: {isValid: true, hasData: false},
  cvn: {isValid: true, hasData: false},
  accountHolderName: {isValid: true, hasData: false, value: ''},
  addr1: {isValid: true, hasData: false, value: ''},
  city: {isValid: true, hasData: false, value: ''},
  district: {isValid: true, hasData: false, value: ''},
  postalCode: {isValid: true, hasData: false, value: ''},
  country: {isValid: true, hasData: true, value: ''},
  customerDescription: {isValid: true, hasData: true, value: ''},
  cardTypeValid: 'generic',
  cvnLenght: 3,
  popup: null,
  isPending: false,
  failureMessage: false,
  enableContainer: false,
  successMessage: false,
  submitted: false
}
const CashBoxHOA = (options) => {
  const setupHOA = () => {
    let vindicia = window.vindicia
    vindicia.setup({
      formId: options.formId, // dom element of form
      vindiciaServer: process.env.REACT_APP_CASHBOX_SERVER,
      hostedFields: {
        cardNumber: {
          selector: '#vin_PaymentMethod_creditCard_account',
          placeholder: options.cardNumber.placeholder
        },
        expirationDate: {
          selector: '#vin_PaymentMethod_creditCard_expirationDate',
          placeholder: options.expirationDate.placeholder,
          format: options.expirationDate.format
        },
        cvn: {
          selector: '#vin_PaymentMethod_creditCard_cvn',
          placeholder: options.cvn.placeholder
        },
        styles: options.styles
      },
      onSubmitEvent: options.onSubmitEvent,
      onSubmitCompleteEvent: options.onSubmitCompleteEvent,
      onVindiciaFieldEvent: options.onVindiciaFieldEvent,
      iframeHeightPadding: options.iframeHeightPadding
    })
  }

  if (!window.vindicia) {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', process.env.REACT_APP_CASHBOX_JS)
    document.head.appendChild(script)
    script.onload = setupHOA
  } else {
    // Removes an element from the document
    var element = document.getElementById('vindicia-hosted-frame-master')
    if (element) {
      element.parentNode.removeChild(element)
      setupHOA()
    }
  }
}
class PaymentContainer extends React.Component {
  static contextType = PageContext

  constructor(props) {
    super(props)
    this.state = Object.assign({}, intialState)

    // state being sent via router props
    if (props.location.state) {
      Object.assign(this.state, props.location.state)
    }

    this.cancelClick = this.cancelClick.bind(this)
    this.getWebSessionFinalizePayment = this.getWebSessionFinalizePayment.bind(this)
    this.isHOALoaded = this.isHOALoaded.bind(this)
    this.hideUpdateFailure = this.hideUpdateFailure.bind(this)
  }

  componentDidMount() {
    const {getWebSessionInitializePayment, subscriptions} = this.props
    const {fromSelfCare} = this.state

    if (subscriptions.createSubscriptionModel.items.length === 0 && !fromSelfCare) {
      history.replace('/subscription')
      return
    }

    const getWebSessionInitializePaymentPromise = Promise.resolve(getWebSessionInitializePayment())
    getWebSessionInitializePaymentPromise.then(() => {
      this.setupCashBoxHOA()
      this.intervalID = setInterval(this.isHOALoaded, 500)
    })

    this.context.setPageHeader('MY ACCOUNT')
    this.context.setPageSubHeader('ENTER PAYMENT INFORMATION')
  }

  isHOALoaded = () => {
    if (window.vindicia && window.vindicia.isLoaded()) {
      clearInterval(this.intervalID)
      this.setState({enableContainer: true})
    }
  }

  cancelClick = (event, data) => {
    document.body.removeEventListener('submitCompleteEvent', this.getWebSessionFinalizePayment)
    history.push('/subscription')
  }

  handleChange = ({target}) => {
    // if has own property doesn;t work do object.keys.include
    const propName = target.id.substring(target.id.lastIndexOf('_') + 1)
    if (this.state.hasOwnProperty(propName)) {
      this.setState({
        [propName]: {isValid: !!target.value, hasData: !!target.value, value: target.value}
      })
    }
  }

  hideUpdateFailure = () => {
    this.setState({failureMessage: false})
  }

  validateHostedFieldsAll = () => {
    const {cardNumber, expirationDate, cvn, accountHolderName, addr1, city, district, postalCode} = this.state
    const {vindicia} = window
    this.setState({enableContainer: false})

    const vindiciaValid = vindicia.isValid()
    return (
      vindiciaValid &&
      cardNumber.isValid &&
      cardNumber.hasData &&
      expirationDate.isValid &&
      expirationDate.hasData &&
      cvn.isValid &&
      cvn.hasData &&
      accountHolderName.isValid &&
      accountHolderName.hasData &&
      addr1.isValid &&
      addr1.hasData &&
      city.isValid &&
      city.hasData &&
      district.isValid &&
      district.hasData &&
      postalCode.isValid &&
      postalCode.hasData
    )
  }

  validateHostedFields = (event) => {
    const {fieldType, isValid, cardType, dataLength} = event.detail
    const {cardTypeValid, cvnLenghtValid} = this.state
    const hasData = dataLength > 0
    let cvnLenght = 3
    //setState is only called once in this decesion tree
    switch (fieldType) {
      case 'expirationDate':
        this.setState({expirationDate: {isValid: isValid, hasData: hasData}})
        break
      case 'cardNumber':
        if (cardType && cardType === 'amex') {
          cvnLenght = 4
        } else {
          cvnLenght = 3
        }
        if (cardType && ((cardType === 'amex' && cvnLenghtValid === 3) || (cardType !== 'amex' && cvnLenghtValid === 4))) {
          this.setState({
            cardTypeValid: cardType,
            cardNumber: {isValid: isValid, hasData: hasData},
            cvn: {isValid: false, hasData: hasData},
            cvnLenght: cvnLenght
          })
        } else {
          this.setState({
            cardTypeValid: cardType,
            cardNumber: {isValid: isValid, hasData: hasData},
            cvnLenght
          })
        }
        break
      case 'cvn':
        cardTypeValid && ((cardTypeValid === 'amex' && dataLength !== 4) || (cardTypeValid !== 'amex' && dataLength !== 3))
          ? this.setState({
              cvnLenghtValid: dataLength,
              cvn: {isValid: false, hasData: hasData}
            })
          : this.setState({
              cvnLenghtValid: dataLength,
              cvn: {isValid: isValid, hasData: hasData}
            })
        break
      default:
        break
    }
  }

  getWebSessionFinalizePayment = () => {
    const {getWebSessionFinalizePayment, getWebSessionInitializePayment, webSession} = this.props
    this.setState({submitted: true})

    const getWebSessionFinalizePaymentPromise = getWebSessionFinalizePayment({
      VID: webSession.webSessionInitializePayment.session.VID,
      merchantPaymentMethodId: webSession.webSessionInitializePayment.session.privateFormValues[1].value
    })

    getWebSessionFinalizePaymentPromise
      .then(() => {
        window.vindicia.clearData()
        // This is a hack because vindicia doesn't clear their event model until page unload....which doesn't happen in reactjs
        document.body.removeEventListener('submitCompleteEvent', this.getWebSessionFinalizePayment)
        this.state.fromSelfCare ? history.push('/subscription') : history.replace('/subscription/billing/summary')
      })
      .catch((err) => {
        window.vindicia.clearData()
        this.setState(Object.assign({}, intialState, {enableContainer: true, failureMessage: true}))
        getWebSessionInitializePayment().then(() => {
          this.setState({
            submitted: false
          })
        })
      })

    return false
  }

  setupCashBoxHOA = () => {
    const options = {
      onSubmitEvent: this.validateHostedFieldsAll,
      onSubmitCompleteEvent: this.getWebSessionFinalizePayment,
      onVindiciaFieldEvent: this.validateHostedFields,
      formId: 'rebar-payment-update-form',
      cardNumber: {
        value: '',
        placeholder: '' //'Enter Card Number'
      },
      expirationDate: {
        value: '',
        placeholder: '', //'MM/YY',
        format: 'MM/YY'
      },
      cvn: {
        value: '',
        placeholder: '' //'###'
      },
      styles: {
        input: {
          'font-family': "'Lato','Helvetica Neue','Arial','Helvetica','sans-serif'",
          margin: '0',
          outline: '0',
          '-webkit-appearance': 'none',
          'tap-highlight-color': 'rgba(255,255,255,0)',
          'line-height': '16.341px;',
          padding: '0px',
          'font-size': '14px',
          background: '#fff'
        }
      },
      iframeHeightPadding: 0
    }
    CashBoxHOA(options)
  }

  render() {
    const {webSession} = this.props
    const {
      cardNumber,
      customerDescription,
      expirationDate,
      enableContainer,
      cvn,
      accountHolderName,
      addr1,
      city,
      district,
      postalCode,
      cvnLenght
    } = this.state

    return (
      <Grid divided="vertically" stackable columns={16} padded className="condensed" centered>
        <Grid.Column width={6}>
          <Form id="rebar-payment-update-form" size="large" loading={!enableContainer}>
            <Form.Input
              id="vin_PaymentMethod_accountHolderName"
              name="vin_PaymentMethod_accountHolderName"
              label="Cardholder Name"
              className="payment-input-field--required"
              error={!accountHolderName.isValid}
              value={accountHolderName.value}
              onChange={this.handleChange}
              fluid
            />
            {!accountHolderName.isValid ? (
              <Label basic color="red" margin="0">
                Please enter the Cardholder Name
              </Label>
            ) : null}
            <Form.Input
              id="vin_PaymentMethod_customerDescription"
              name="vin_PaymentMethod_customerDescription"
              label="Card Account Nickname"
              className="payment-input-field"
              onChange={this.handleChange}
              value={customerDescription.value}
              fluid
            />
            <Form.Group class="column row">
              <div className="sixteen wide field">
                <label
                  className={cn('payment-hoa-input-field-label', {
                    'payment-hoa-input-field-label--invalid': !cardNumber.isValid
                  })}
                >
                  Credit Card
                </label>
                <Message
                  negative={!cardNumber.isValid}
                  className={cn('payment-hoa-input-field', {
                    'payment-hoa-input-field--invalid': !cardNumber.isValid
                  })}
                >
                  <div id="vin_PaymentMethod_creditCard_account" />
                </Message>
                {!cardNumber.isValid ? (
                  !cardNumber.hasData ? (
                    <Label basic color="red">
                      Please enter the Credit Card Number
                    </Label>
                  ) : (
                    <Label basic color="red">
                      Please enter a Valid Credit Card Number
                    </Label>
                  )
                ) : null}
              </div>
            </Form.Group>
            <Form.Group class="column row">
              <div className="eight wide field">
                <label
                  className={cn('payment-hoa-input-field-label', {
                    'payment-hoa-input-field-label--invalid': !expirationDate.isValid
                  })}
                >
                  Expiration Date (MM/YY)
                </label>
                <Message
                  negative={!expirationDate.isValid}
                  className={cn('payment-hoa-input-field', {
                    'payment-hoa-input-field--invalid': !expirationDate.isValid
                  })}
                >
                  <div id="vin_PaymentMethod_creditCard_expirationDate" />
                </Message>
                {!expirationDate.isValid ? (
                  !expirationDate.hasData ? (
                    <Label basic color="red">
                      Please enter the Expiration Date
                    </Label>
                  ) : (
                    <Label basic color="red">
                      Please enter a Valid Expiration Date
                    </Label>
                  )
                ) : null}
              </div>
              <div className="eight wide field">
                <label
                  className={cn('payment-hoa-input-field-label', {
                    'payment-hoa-input-field-label--invalid': !cvn.isValid
                  })}
                >
                  Card Verification Number
                </label>
                <Message
                  negative={!cvn.isValid}
                  className={cn('payment-hoa-input-field', {
                    'payment-hoa-input-field--invalid': !cvn.isValid
                  })}
                >
                  <div id="vin_PaymentMethod_creditCard_cvn" />
                </Message>
                {!cvn.isValid ? (
                  !cvn.hasData ? (
                    <Label basic color="red">
                      Please enter the Card Verification Number
                    </Label>
                  ) : (
                    <Label basic color="red">
                      Please enter a Valid {cvnLenght} digit Card Verification Number
                    </Label>
                  )
                ) : null}
              </div>
            </Form.Group>
            <Form.Input
              id="vin_PaymentMethod_billingAddress_addr1"
              name="vin_PaymentMethod_billingAddress_addr1"
              label="Billing Address"
              className="payment-input-field--required"
              error={!addr1.isValid}
              value={addr1.value}
              onChange={this.handleChange}
              fluid
            />
            {!addr1.isValid ? (
              <Label basic color="red">
                Please enter the Billing Address
              </Label>
            ) : null}
            <Form.Input
              id="vin_PaymentMethod_billingAddress_city"
              name="vin_PaymentMethod_billingAddress_city"
              label="City"
              className="payment-input-field--required"
              error={!city.isValid}
              value={city.value}
              onChange={this.handleChange}
              fluid
            />
            {!city.isValid ? (
              <Label basic color="red">
                Please enter the City
              </Label>
            ) : null}
            <Form.Group widths="equal">
              <Form.Input
                id="vin_PaymentMethod_billingAddress_district"
                name="vin_PaymentMethod_billingAddress_district"
                label="State"
                className="payment-input-field--required"
                error={!district.isValid}
                value={district.value}
                onChange={this.handleChange}
                fluid
              />
              {!district.isValid ? (
                <Label basic color="red">
                  Please enter the State
                </Label>
              ) : null}
              <Form.Input
                id="vin_PaymentMethod_billingAddress_postalCode"
                name="vin_PaymentMethod_billingAddress_postalCode"
                label="Zip"
                className="payment-input-field--required"
                error={!postalCode.isValid}
                value={postalCode.value}
                onChange={this.handleChange}
                fluid
              />
              {!postalCode.isValid ? (
                <Label basic color="red">
                  Please enter the Zip Code
                </Label>
              ) : null}
            </Form.Group>
            <input type="hidden" name="vin_PaymentMethod_billingAddress_country" value="US" />
            <input
              type="hidden"
              name="vin_WebSession_vid"
              value={
                webSession.webSessionInitializePayment.session ? webSession.webSessionInitializePayment.session.VID : ''
              }
            />
            <input type="hidden" name="vin_WebSession_version" value="22.0" />
            <input type="hidden" name="vin_PaymentMethod_type" value="CreditCard" />
            <Button
              type="submit"
              size="large"
              disabled={
                this.state.submitted ||
                !(
                  cardNumber.isValid &&
                  cardNumber.hasData &&
                  expirationDate.isValid &&
                  expirationDate.hasData &&
                  cvn.isValid &&
                  cvn.hasData &&
                  accountHolderName.isValid &&
                  accountHolderName.hasData &&
                  addr1.isValid &&
                  addr1.hasData &&
                  city.isValid &&
                  city.hasData &&
                  district.isValid &&
                  district.hasData &&
                  postalCode.isValid &&
                  postalCode.hasData
                )
              }
            >
              Save Payment Information
            </Button>
            <Button size="large" onClick={this.cancelClick}>
              Cancel
            </Button>
            <Confirm
              open={this.state.failureMessage}
              onCancel={this.hideUpdateFailure}
              onConfirm={this.hideUpdateFailure}
              header={
                <span className="header">
                  <Icon name="exclamation circle" color="red" />
                  Payment Failure
                </span>
              }
              content="An error occurred while trying to create the payment method. Please try another card."
            />
          </Form>
          {/* <div>
            <p>Or use PayPal (custom button available)</p>
          </div> */}
          {/* <Form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_xclick-subscriptions" />
            <input type="hidden" name="business" value="jpaine@wcapra.com" />
            <input type="hidden" name="lc" value="US" />
            <input type="hidden" name="item_name" value="TestSub" />
            <input type="hidden" name="item_number" value="123" />
            <input type="hidden" name="no_note" value="1" />
            <input type="hidden" name="src" value="1" />
            <input type="hidden" name="a3" value="20.00" />
            <input type="hidden" name="p3" value="1" />
            <input type="hidden" name="t3" value="M" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="hidden" name="bn" value="PP-SubscriptionsBF:btn_subscribeCC_LG.gif:NonHostedGuest" />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif"
              border="0"
              name="submit"
              alt="PayPal - The safer, easier way to pay online!"
            />
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </Form> */}
        </Grid.Column>
      </Grid>
    )
  }
}

function mapStateToProps(store) {
  return {
    webSession: store.webSession,
    subscriptions: store.subscriptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getWebSessionInitializePayment: () => {
      return dispatch(getWebSessionInitializePayment())
    },
    getWebSessionFinalizePayment: (params) => {
      return dispatch(getWebSessionFinalizePayment(params))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentContainer)
