import {ACTION_TYPES} from './actions.js'

const initialState = {}
export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.PRODUCTS_SUCCESS:
      return Object.assign({}, payload.results)
    case ACTION_TYPES.PRODUCTS_FAILURE:
      return Object.assign({}, payload.error)
    case ACTION_TYPES.PRODUCTS_REQUEST:
    default:
      return state
  }
}
