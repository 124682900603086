import React from 'react'
import {Segment, Container, Visibility, Responsive, Header} from 'semantic-ui-react'

import HomepageHeading from '../components/HomepageHeading'
import MarketingTopNav from '../components/MarketingTopNav'
import FooterComponent from '../components/FooterComponent'

import {PageContext} from '../contexts/pageContext'

class DesktopLayout extends React.Component {
  state = {fixedMenuVisibility: false}
  hideFixedMenu = () => this.setState({fixedMenuVisibility: false})
  showFixedMenu = () => this.setState({fixedMenuVisibility: true})

  render() {
    const {children, showHomepageHeading, disabled} = this.props
    const {fixedMenuVisibility} = this.state

    return (
      <Responsive
        minWidth={Responsive.onlyTablet.minWidth}
        style={{display: 'flex', minHeight: '100vh', flexDirection: 'column'}}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
          onOnScreen={this.hideFixedMenu}
        >
          <Segment inverted attached="top" textAlign="center" vertical>
            <MarketingTopNav isFixedMenuVisible={fixedMenuVisibility} disabled={disabled} />
          </Segment>
          {!!showHomepageHeading && <HomepageHeading />}
        </Visibility>
        <PageContext.Consumer>
          {(pageContext) => (
            <>
              <Segment attached>
                <Container>
                  <Header as="h2">{pageContext.pageHeader}</Header>
                </Container>
              </Segment>
              <Segment attached textAlign="center">
                <Header as="h3">{pageContext.pageSubHeader}</Header>
              </Segment>
            </>
          )}
        </PageContext.Consumer>
        <Segment attached style={{flex: 1}}>
          {children}
        </Segment>
        <Segment inverted vertical attached="bottom">
          <FooterComponent />
        </Segment>
      </Responsive>
    )
  }
}

export default DesktopLayout
