import React from 'react'
import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {getProductsByID} from '../../redux/products/thunks'
import {getBaseProducts} from '../../redux/products/selectors'
import SimpleLoader from '../../components/SimpleLoader'
import PackagePriceTable from '../../components/PackagePriceTable'

class PackagesContainer extends React.Component {
  componentDidMount() {
    this.props.getProductsById()
  }

  render() {
    const {products, baseProducts} = this.props
    const basePackages = baseProducts

    return (
      <SimpleLoader active={basePackages.length === 0} message="Loading Products">
        <PackagePriceTable products={products} />
      </SimpleLoader>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    baseProducts: getBaseProducts(store),
    products: store.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductsById: () => {
      return dispatch(getProductsByID())
    }
  }
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PackagesContainer)
)
