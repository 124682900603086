export const ACTION_TYPES = {
  TRANSACTIONS: 'transactions/TRANSACTIONS',
  TRANSACTIONS_REQUEST: 'transactions/TRANSACTIONS_REQUEST',
  TRANSACTIONS_SUCCESS: 'transactions/TRANSACTIONS_SUCCESS',
  TRANSACTIONS_FAILURE: 'transactions/TRANSACTIONS_FAILURE'
}

export const transactionsRequest = (cancelRequest) => {
  return {type: ACTION_TYPES.TRANSACTIONS_REQUEST, payload: cancelRequest}
}

export const transactionsSuccess = (results) => ({
  type: ACTION_TYPES.TRANSACTIONS_SUCCESS,
  payload: {...results}
})

export const transactionsFailure = (error) => ({
  type: ACTION_TYPES.TRANSACTIONS_FAILURE,
  payload: {...error}
})
