import React from 'react'
import {Segment, Visibility, Responsive} from 'semantic-ui-react'

import HomepageHeading from '../../components/HomepageHeading'
import MarketingTopNav from '../../components/MarketingTopNav'

class DesktopMarketingContainer extends React.Component {
  state = {fixedMenuVisibility: false}
  hideFixedMenu = () => this.setState({fixedMenuVisibility: false})
  showFixedMenu = () => this.setState({fixedMenuVisibility: true})

  render() {
    const {children, showHomepageHeading, disabled} = this.props
    const {fixedMenuVisibility} = this.state

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
          onOnScreen={this.hideFixedMenu}
        >
          <Segment inverted textAlign="center" vertical>
            <MarketingTopNav isFixedMenuVisible={fixedMenuVisibility} disabled={disabled} />
          </Segment>
        </Visibility>
        {!!showHomepageHeading && <HomepageHeading />}
        {children}
      </Responsive>
    )
  }
}

export default DesktopMarketingContainer
