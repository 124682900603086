import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App.js'
import store from './redux/configureStore.js'
import Routes from './routes'
import {Router as BrowserRouter} from 'react-router'
import {Provider} from 'react-redux'
import browserHistory from './history'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={browserHistory}>
      <App>
        <Routes />
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
