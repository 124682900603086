import axios from 'axios'
import {
  accountsRequest,
  accountsRequestSuccess,
  accountsRequestFailure,
  accountsCreateRequest,
  accountsCreateRequestSuccess,
  accountsCreateRequestFailure,
  accountsUpdatePaymentMethod
} from './actions'
import verifier from '../../utils/jwtVerifier'
import authService from '../../services/authService'

const axiosClient = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

const axiosClient2 = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

export function updateAccountPaymentMethod(account) {
  return function(dispatch, getState) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          accountsRequestFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText
          })
        )
      )
    }

    return Promise.resolve(dispatch(accountsUpdatePaymentMethod(account)))
  }
}

export function getCashBoxAccount() {
  return function(dispatch, getState) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          accountsRequestFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText
          })
        )
      )
    }
    const accountId = dataDecoded.sub
    if (!accountId) return null

    // get the cashbox account, if it exists
    const getAccountPromise = axiosClient.get(`/accounts/${encodeURI(accountId)}`, {
      headers: {Authorization: authSvc.getIdToken()}
    })

    dispatch(accountsRequest())

    return getAccountPromise.then(function(response) {
      if (response.data.object === 'Error') {
        // account doesn't exist. let's create it
        return dispatch(createCashBoxAccountPromise)
          .then((acct) => {
            dispatch(accountsCreateRequestSuccess(acct))
          })
          .catch((err) => {
            dispatch(
              accountsCreateRequestFailure({
                status: '',
                statusText: err.message
              })
            )
            throw new Error('error creating account')
          })
      } else {
        dispatch(accountsRequestSuccess(response.data))
        return response.data
      }
    })
  }
}

export function createCashBoxAccountPromise(dispatch) {
  const authSvc = new authService()
  const claimPrefix = 'https://rebartechnology.wcapra.com/'
  const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
  if (errDecoded) {
    dispatch(
      accountsRequestFailure({
        status: errDecoded.status,
        statusText: errDecoded.statusText
      })
    )
    throw new Error('invalid token')
  }

  const createAccountPromise = axiosClient2.post(
    '/accounts',
    {
      id: dataDecoded.sub,
      name: dataDecoded[`${claimPrefix}name`],
      email: dataDecoded[`${claimPrefix}email`],
      email_type: 'html'
    },
    {
      headers: {Authorization: authSvc.getIdToken()}
    }
  )

  dispatch(accountsCreateRequest())

  return createAccountPromise.then(function(response) {
    if (response.data.object === 'Error') {
      throw new Error(response.data.message)
    }
    return response.data
  })
}
