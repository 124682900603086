import {ACTION_TYPES} from './actions.js'

const initialState = {}
export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.CASH_BOX_SUCCESS:
      return payload
    case ACTION_TYPES.CASH_BOX_FAILURE:
      return payload
    case ACTION_TYPES.CASH_BOX_REQUEST:
    default:
      return state
  }
}
