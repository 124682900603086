import React from 'react'
import {Sidebar, Segment, Container, Icon, Menu, Responsive, Button} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {UserContext} from '../../contexts/userContext'
import HomepageHeading from '../../components/HomepageHeading'
import withAPISecurity from '../../hocs/withAPISecurity'

class MobileMarketingContainer extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      activeMenuItem: 'homeMenuItem',
      sidebarOpened: false
    }

    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleLogoutClick = this.handleLogoutClick.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname.indexOf('packages') > 0) {
      return {activeMenuItem: 'packageMenuItem'}
    } else if (props.location.pathname.indexOf('subscription') > 0) {
      return {activeMenuItem: 'subscriptionMenuItem'}
    } else {
      return {activeMenuItem: 'homeMenuItem'}
    }
  }

  handleMenuClick = (event, {name}) => {
    if (name === 'packageMenuItem') {
      this.props.history.push('/packages')
    } else if (name === 'subscriptionMenuItem') {
      this.props.history.push('/subscription')
    } else if (name === 'homeMenuItem') {
      this.props.history.push('/')
    }
  }

  handleLoginClick = (event, {name}) => {
    const {authService} = this.context
    authService.login(name === 'signUpButton')
  }

  handleLogoutClick = () => {
    return this.context.authService.logout()
  }

  getMessageProps = () => ({
    hidden: !this.state.hasAPIError,
    visible: this.state.hasAPIError
  })

  handleRef = (c) => {
    this.inputRef = c
  }

  handleAPIUnlock = (event, data) => {
    this.setState({loading: true})

    return this.context.authService
      .unlockAPI(this.inputRef.inputRef.value)
      .then((response) => {
        this.setState({loading: false, hasAPIError: false, isModalOpen: false})
      })
      .then(() => this.handleLoginClick())
      .catch((err) => {
        if (err.response.status === 502) {
          // This is the error code returned from the API gateway/Lambda integration
          this.setState({
            loading: false,
            hasAPIError: true,
            errorMessage: 'Invalid API key.  Please check your inputs and try again'
          })
        } else {
          this.setState({
            loading: false,
            hasAPIError: true,
            errorMessage: err.response.data.message
          })
        }
      })
  }

  handleSidebarHide = () => this.setState({sidebarOpened: false})
  handleToggle = () => this.setState({sidebarOpened: true})
  handleMenuClick = (event, {name}) => {
    this.setState({activeMenuItem: name})
  }

  render() {
    const {children, showHomepageHeading} = this.props
    const {sidebarOpened, activeMenuItem} = this.state
    const ApiSecureMenuItem = withAPISecurity(Menu.Item)

    return (
      <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
          <Menu.Item name="homeMenuItem" as="a" active={activeMenuItem === 'homeMenuItem'} href="/">
            Home
          </Menu.Item>
          <Menu.Item name="packageMenuItem" as="a" active={activeMenuItem === 'packageMenuItem'} href="/packages">
            Packages
          </Menu.Item>
          <Menu.Item
            name="subscriptionMenuItem"
            as="a"
            active={activeMenuItem === 'subscriptionMenuItem'}
            {...(this.context.authService.isAuthenticated() ? {href: '/subscription'} : {disabled: true})}
          >
            Subscription
          </Menu.Item>
          {this.context.authService.isAuthenticated() ? (
            <Menu.Item as="a" onClick={this.handleLogoutClick}>
              Log Out
            </Menu.Item>
          ) : (
            <>
              <ApiSecureMenuItem name="loginButton" as="a" onAPIUnlocked={this.handleLoginClick}>
                Log in
              </ApiSecureMenuItem>
              <ApiSecureMenuItem name="signUpButton" as="a" onAPIUnlocked={this.handleLoginClick}>
                Sign Up
              </ApiSecureMenuItem>
            </>
          )}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" style={{minHeight: 50, padding: '0em 0em'}} vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <Button color="facebook" icon>
                    <Icon name="facebook" />
                  </Button>
                  <Button color="twitter" icon>
                    <Icon name="twitter" />
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            {!!showHomepageHeading && <HomepageHeading mobile />}
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

export default withRouter(MobileMarketingContainer)
