import React from 'react'
import {Sidebar, Confirm, Segment, Container, Icon, Menu, Responsive, Button, Header} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {UserContext} from '../contexts/userContext'
import {PageContext} from '../contexts/pageContext'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {cancelSubscriptionAutoBill, getSubscriptions} from '../redux/subscriptions/thunks'
import {makeCurrentSubscriptionState} from '../redux/subscriptions/selectors'
import HomepageHeading from '../components/HomepageHeading'
import withAPISecurity from '../hocs/withAPISecurity'
import FooterComponent from '../components/FooterComponent'

class MobileMarketingContainer extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      activeMenuItem: 'homeMenuItem',
      sidebarOpened: false,
      isCancelSubscriptionDialogOpen: false
    }

    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleLogoutClick = this.handleLogoutClick.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname.indexOf('packages') > 0) {
      return {activeMenuItem: 'packageMenuItem'}
    } else {
      return {activeMenuItem: 'homeMenuItem'}
    }
  }

  handleLoginClick = (event, {name}) => {
    const {authService} = this.context
    authService.login(name === 'signUpButton')
  }

  handleLogoutClick = () => {
    return this.context.authService.logout()
  }

  getMessageProps = () => ({
    hidden: !this.state.hasAPIError,
    visible: this.state.hasAPIError
  })

  onCancelSubscriptionClick = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: true,
      sidebarOpened: false
    })
  }

  onSubscriptionCancelAbort = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: false
    })
  }

  onSubscriptionCancelConfirm = () => {
    const {cancelSubscriptionAutoBill, currentSubscription, getSubscriptions} = this.props
    this.setState({
      enableContainer: false,
      isCancelSubscriptionDialogOpen: false
    })
    cancelSubscriptionAutoBill(currentSubscription.id).then(() => {
      getSubscriptions().then(() => this.setState({sidebarOpened: false}))
    })
  }

  handleRef = (c) => {
    this.inputRef = c
  }

  handleAPIUnlock = (event, data) => {
    this.setState({loading: true})

    return this.context.authService
      .unlockAPI(this.inputRef.inputRef.value)
      .then((response) => {
        this.setState({loading: false, hasAPIError: false, isModalOpen: false})
      })
      .then(() => this.handleLoginClick())
      .catch((err) => {
        if (err.response.status === 502) {
          // This is the error code returned from the API gateway/Lambda integration
          this.setState({
            loading: false,
            hasAPIError: true,
            errorMessage: 'Invalid API key.  Please check your inputs and try again'
          })
        } else {
          this.setState({
            loading: false,
            hasAPIError: true,
            errorMessage: err.response.data.message
          })
        }
      })
  }

  handleSidebarHide = () => this.setState({sidebarOpened: false})
  handleToggle = () => this.setState({sidebarOpened: true})

  render() {
    const {children, showHomepageHeading} = this.props
    const {sidebarOpened, activeMenuItem, isCancelSubscriptionDialogOpen} = this.state
    const ApiSecureMenuItem = withAPISecurity(Menu.Item)

    return (
      <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
          <Menu.Item name="homeMenuItem" as="a" active={activeMenuItem === 'homeMenuItem'} href="/">
            Home
          </Menu.Item>
          <Menu.Item name="packageMenuItem" as="a" active={activeMenuItem === 'packageMenuItem'} href="/packages">
            Packages
          </Menu.Item>
          <Menu.Item>
            Subscription
            <Menu.Menu>
              <Menu.Item
                as="div"
                onClick={this.handleSidebarHide}
                {...(this.context.authService.isAuthenticated() ? {} : {disabled: true})}
              >
                <NavLink className="nav-link" activeClassName="nav-link--active" exact to="/subscription">
                  Subscription Dashboard
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" onClick={this.handleSidebarHide}>
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link--active"
                  to={{
                    pathname: '/billing/payment',
                    search: '',
                    hash: '',
                    state: {fromSelfCare: true}
                  }}
                >
                  Update Payment Method
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" onClick={this.handleSidebarHide}>
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link--active"
                  to={{
                    pathname: '/subscription/products/packages',
                    search: '',
                    hash: '',
                    state: {isUpdatingSubscription: true}
                  }}
                >
                  Change Subscription
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" onClick={this.handleSidebarHide}>
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link--active"
                  to={{
                    pathname: '/billing/transactions',
                    search: '',
                    hash: '',
                    state: undefined
                  }}
                >
                  Billing Transaction History
                </NavLink>
              </Menu.Item>
              <Menu.Item onClick={this.onCancelSubscriptionClick}>Cancel Subscription</Menu.Item>
            </Menu.Menu>
          </Menu.Item>
          {this.context.authService.isAuthenticated() ? (
            <Menu.Item as="a" onClick={this.handleLogoutClick}>
              Log Out
            </Menu.Item>
          ) : (
            <>
              <ApiSecureMenuItem name="loginButton" as="a" onAPIUnlocked={this.handleLoginClick}>
                Log in
              </ApiSecureMenuItem>
              <ApiSecureMenuItem name="signUpButton" as="a" onAPIUnlocked={this.handleLoginClick}>
                Sign Up
              </ApiSecureMenuItem>
            </>
          )}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" style={{minHeight: 50, padding: '0em 0em'}} vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <Button color="facebook" icon>
                    <Icon name="facebook" />
                  </Button>
                  <Button color="twitter" icon>
                    <Icon name="twitter" />
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {!!showHomepageHeading && <HomepageHeading mobile />}
          <PageContext.Consumer>
            {(pageContext) => (
              <>
                <Segment attached>
                  <Container>
                    <Header as="h2">{pageContext.pageHeader}</Header>
                  </Container>
                </Segment>
                <Segment attached textAlign="center">
                  <Header as="h3">{pageContext.pageSubHeader}</Header>
                </Segment>
              </>
            )}
          </PageContext.Consumer>
          <Segment attached style={{flex: 1}}>
            {children}
          </Segment>
          <Segment inverted vertical attached="bottom">
            <FooterComponent mobile />
          </Segment>
        </Sidebar.Pusher>

        <Confirm
          open={isCancelSubscriptionDialogOpen}
          onCancel={this.onSubscriptionCancelAbort}
          onConfirm={this.onSubscriptionCancelConfirm}
          content="Are you sure? This action cannot be undone"
          header="Cancel Subscription"
        />
      </Responsive>
    )
  }
}

const makeMapStateToProps = () => {
  const currentSubscription = makeCurrentSubscriptionState()
  return (state, props) => ({
    currentSubscription: currentSubscription(state.subscriptions.subscriptionModel)
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: () => {
      return dispatch(getSubscriptions())
    },
    cancelSubscriptionAutoBill: (subscriptionId) => {
      return dispatch(cancelSubscriptionAutoBill(subscriptionId))
    }
  }
}

export default withRouter(
  connect(
    makeMapStateToProps(),
    mapDispatchToProps
  )(MobileMarketingContainer)
)
