import React from 'react'
import {Grid, Container, List, Icon, Header, Label} from 'semantic-ui-react'

export default ({mobile}) => (
  <Container>
    <Grid divided inverted stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <Header inverted as={mobile ? 'h3' : 'h4'} content="About" />
          <List link inverted {...(mobile ? {size: 'tiny'} : {})}>
            <List.Item as="a">Sitemap</List.Item>
            <List.Item as="a">Contact Us</List.Item>
            <List.Item as="a">Religious Ceremonies</List.Item>
            <List.Item as="a">Gazebo Plans</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <Header inverted as={mobile ? 'h3' : 'h4'} content="Services" />
          <List link inverted {...(mobile ? {size: 'tiny'} : {})}>
            <List.Item as="a">Banana Pre-Order</List.Item>
            <List.Item as="a">DNA FAQ</List.Item>
            <List.Item as="a">How To Access</List.Item>
            <List.Item as="a">Favorite X-Men</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={7}>
          <Label basic as="a" href="https://secure.prodtest.sj.vindicia.com/login/secure/" target="blank">
            <Icon name="external" />
            CashBox Portal
          </Label>
          <Header as={mobile ? 'h3' : 'h4'} inverted>
            Footer Header
          </Header>
          <p>Extra space for a call to action inside the footer that could help re-engage users.</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
)
