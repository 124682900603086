import {combineReducers} from 'redux'
import {ACTION_TYPES} from './actions.js'
const intialState = {}

export function webSessionInitializePayment(state = intialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.WEB_SESSION_INITIALIZE_SUCCESS:
    case ACTION_TYPES.WEB_SESSION_INITIALIZE_FAILURE:
      return Object.assign({}, payload)
    case ACTION_TYPES.WEB_SESSION_INITIALIZE_REQUEST:
      return intialState
    default:
      return state
  }
}

export function webSessionFinalizePayment(state = intialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.WEB_SESSION_FINALIZE_SUCCESS:
    case ACTION_TYPES.WEB_SESSION_FINALIZE_FAILURE:
      return Object.assign({}, payload)
    case ACTION_TYPES.WEB_SESSION_FINALIZE_REQUEST:
    case ACTION_TYPES.WEB_SESSION_FINALIZE_RESET:
      return intialState
    default:
      return state
  }
}

export default combineReducers({
  webSessionInitializePayment,
  webSessionFinalizePayment
})
