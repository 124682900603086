import {createSelector} from 'reselect'

export const getBaseProducts = createSelector(
  (state) => state.products,
  (products) => {
    if (!products) return []
    return Object.keys(products).filter((key) => products[key].productType === 'BASE')
  }
)

export const getEnchancementProducts = createSelector(
  (state) => state.products,
  (products) => {
    if (!products) return []
    return Object.keys(products).filter((key) => products[key].productType === 'ENHANCEMENT')
  }
)
