import createHistory from 'history/createBrowserHistory'

class BrowserHistory {
  constructor() {
    if (!BrowserHistory.instance) {
      this._singletonHistory = createHistory()
      BrowserHistory.instance = this
    }
    return BrowserHistory.instance
  }

  get() {
    return this._singletonHistory
  }
}

const instance = new BrowserHistory()
Object.freeze(instance)

export default instance.get()
