import React from 'react'
import {Grid, Segment, Label, Header, Button} from 'semantic-ui-react'
import {productNames, productFeatures, productSortOrder as sortOrder} from '../utils/configs'

class PackagePriceTable extends React.Component {
  constructor(props) {
    super(props)
    this.buttonRefs = {}
    this.state = {
      activeSubscription: null
    }
  }

  componentDidMount() {
    let refs = this.buttonRefs
    Object.keys(refs).forEach((prop) => {
      refs[prop].ref.className = refs[prop].ref.className + ' ' + this.getBackgroundColor(refs[prop])
    })
  }

  getFeatureColumns = (items) => {
    return items.map((f, index) => {
      return (
        <Grid.Row key={`${index + 10}`}>
          <Grid.Column key={`${index + 100}`} textAlign="center">
            {f}
          </Grid.Column>
        </Grid.Row>
      )
    })
  }

  getAttrs = () => {
    const cssClasses = {}
    if (this.props.condensed) {
      cssClasses.className = 'condensed'
    }
    return cssClasses
  }

  getBackgroundColor = (control) => {
    let className = ''
    if (this.props.rowColor === 'darkSlate') {
      switch (control.ref.nodeName) {
        case 'BUTTON':
        case 'LABEL':
          className = 'element--background-color-grey'
          break
        default:
          className = 'element--background-color-slate-dark'
          break
      }
    }
    return className
  }

  setActiveClass = (key) => {
    if (key === this.props.activeProduct) {
      return {className: 'active b'}
    }
  }

  handleProductClick = (event, data) => {
    this.setState({activeSubscription: data.packageId})
    if (this.props.handleProductOnClick) {
      this.props.handleProductOnClick(event, data)
    }
  }

  render() {
    const {products} = this.props

    const basePackages = Object.keys(products).filter((key) => products[key].productType === 'BASE')
    basePackages.sort((a, b) => {
      if (sortOrder[a] > sortOrder[b]) return 1
      if (sortOrder[a] < sortOrder[b]) return -1
      return 0
    })

    const numberOfGridColumns = basePackages.length * 4 > 0 ? basePackages.length * 4 : 1

    return (
      <Segment>
        <Grid columns={numberOfGridColumns} stackable centered divided verticalAlign="middle">
          <Grid.Row className={this.getBackgroundColor({ref: {nodeName: 'ROW'}})}>
            {basePackages.map((key, idx) => {
              return (
                <Grid.Column key={idx} width={4}>
                  <Segment raised {...this.setActiveClass(key)}>
                    <Grid centered>
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Label
                            attached="top"
                            className={this.getBackgroundColor({
                              ref: {nodeName: 'LABEL'}
                            })}
                          >
                            {productNames[key]}
                          </Label>
                          <Segment>
                            <Header as="h2">{products[key] ? `$${products[key].price}` : 'contact for price'}</Header>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column text="center">
                          <Grid celled="internally" centered stretched {...this.getAttrs()}>
                            {this.getFeatureColumns(productFeatures[key])}
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            packageid={key}
                            {...this.setActiveClass(key)}
                            circular
                            fluid
                            ref={(el) => (this.buttonRefs[`${key}`] = el)}
                            onClick={this.handleProductClick}
                          >
                            select
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              )
            })}
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default PackagePriceTable
