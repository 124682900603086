import React, {Component} from 'react'
import {IntlProvider} from 'react-intl'
import {withRouter} from 'react-router'
import {UserContext} from '../contexts/userContext'
import AuthService from '../services/authService'
import './App.scss'

const authSvc = new AuthService()

class App extends Component {
  state = {
    authService: authSvc,
    locale: 'en',
    theme: '',
    isAuthenticated: authSvc.isAuthenticated
  }

  render() {
    const {children} = this.props
    const {locale} = this.state

    return (
      <UserContext.Provider value={this.state}>
        <IntlProvider locale={locale}>
          <div className="core-layout--viewport">{children}</div>
        </IntlProvider>
      </UserContext.Provider>
    )
  }
}

export default withRouter(App)
