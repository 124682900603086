import React from 'react'
import {Menu, Button, Icon, Container} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {UserContext} from '../contexts/userContext'
import withAPISecurity from '../hocs/withAPISecurity'

class MarketingTopNav extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      errorMessage: '',
      hasAPIError: false,
      isModalOpen: false
    }

    this.handleLogoutClick = this.handleLogoutClick.bind(this)
    this.invokeLogin = this.invokeLogin.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname.indexOf('packages') > 0) {
      return {activeMenuItem: 'packagesMenuItem'}
    } else if (props.location.pathname.indexOf('subscription') > 0) {
      return {activeMenuItem: 'subscriptionMenuItem'}
    } else {
      return {activeMenuItem: 'homeMenuItem'}
    }
  }

  getMessageProps = () => ({
    hidden: !this.state.hasAPIError,
    visible: this.state.hasAPIError
  })

  getAccountButtonProps = (isFixedMenuVisible, disabled) => ({
    isFixedMenuVisible: isFixedMenuVisible,
    disabled: disabled
  })

  invokeLogin = (event, {trigger: name}) => {
    this.context.authService.login(name === 'signUpButton')
  }

  handleLogoutClick = ({trigger: name}) => {
    return this.context.authService.logout()
  }

  getAccountButtons = (isFixedMenuVisible, disabled) => {
    const ApiSecureLogInButton = withAPISecurity(Button)

    return this.context.authService.isAuthenticated() ? (
      <>
        <Menu.Item position="right">Welcome, {this.context.authService.getUserName()}</Menu.Item>
        <Menu.Item>
          <Button as="a" onClick={this.handleLogoutClick} inverted={!isFixedMenuVisible} disabled={disabled}>
            Logout
          </Button>
        </Menu.Item>
      </>
    ) : (
      <>
        <Menu.Item position="right" disabled={disabled}>
          <ApiSecureLogInButton
            name="loginButton"
            as="a"
            inverted={!isFixedMenuVisible}
            disabled={disabled}
            onAPIUnlocked={this.invokeLogin}
          >
            Log In
          </ApiSecureLogInButton>
        </Menu.Item>
        <Menu.Item disabled={disabled}>
          <ApiSecureLogInButton
            name="signUpButton"
            as="a"
            inverted={!isFixedMenuVisible}
            primary={!!isFixedMenuVisible}
            disabled={disabled}
            onAPIUnlocked={this.invokeLogin}
          >
            Sign Up
          </ApiSecureLogInButton>
        </Menu.Item>
      </>
    )
  }

  render() {
    const {disabled, isFixedMenuVisible} = this.props
    const {activeMenuItem} = this.state
    return (
      <Menu
        fixed={isFixedMenuVisible ? 'top' : null}
        inverted={!isFixedMenuVisible}
        pointing={!isFixedMenuVisible}
        secondary={!isFixedMenuVisible}
        size="large"
      >
        <Container>
          <Menu.Item as="a" name="homeMenuItem" active={activeMenuItem === 'homeMenuItem'} href="/" disabled={disabled}>
            Home
          </Menu.Item>
          <Menu.Item as="a" name="packagesMenuItem" active={activeMenuItem === 'packagesMenuItem'} href="/packages">
            Packages
          </Menu.Item>
          {!disabled && this.context.authService.isAuthenticated() ? (
            <Menu.Item
              as="a"
              name="subscriptionMenuItem"
              active={activeMenuItem === 'subscriptionMenuItem'}
              href="/subscription"
            >
              Subscription
            </Menu.Item>
          ) : null}
          {this.getAccountButtons(isFixedMenuVisible, disabled)}
          <Menu.Item disabled={disabled}>
            <Button color="facebook" disabled={disabled} icon>
              <Icon name="facebook" />
            </Button>
            <Button color="twitter" disabled={disabled} icon>
              <Icon name="twitter" />
            </Button>
          </Menu.Item>
        </Container>
      </Menu>
    )
  }
}

export default withRouter(MarketingTopNav)
