import axios from 'axios'

const axiosClient = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

const getClientIP = () => {
  return axiosClient
    .get('https://api.ipify.org?format=json')
    .then((data) => data.data['ip'])
    .catch((e) => {
      // console.log('There was a problem getting the IP address', e)
    })
}

export default getClientIP
