import React from 'react'
import MarketingComponent from '../components/MarketingComponent'

import ResponsiveMarketingContainer from '../containers/homepage/ResponsiveMarketingContainer'

class MarketingLayout extends React.Component {
  render() {
    const showHomepageHeading = !!this.props.showHomepageHeading
    return (
      <ResponsiveMarketingContainer showHomepageHeading={showHomepageHeading}>
        <MarketingComponent />
      </ResponsiveMarketingContainer>
    )
  }
}

export default MarketingLayout
