import axios from 'axios'
import crypto from 'crypto'
import history from '../../history'
import Auth0UniversalLock, {Auth0LoginTypeEnum} from './Auth0UniversalLock'
import jwt from 'jsonwebtoken'

export default class Auth {
  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
    this.renewSession = this.renewSession.bind(this)
    this.isAPIUnlocked = this.isAPIUnlocked.bind(this)
    this.unlockAPI = this.unlockAPI.bind(this)
  }

  get auth0() {
    if (!this._auth0) {
      return new Auth0UniversalLock(Auth0LoginTypeEnum.Login)
    }
    return this._auth0
  }

  getUniversalLoginLock = (loginType) => {
    this._auth0 = new Auth0UniversalLock(loginType)
    return this._auth0
  }

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        history.replace('/subscription')
      } else if (err) {
        history.replace('/')
        alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }

  getAccessToken = () => {
    return localStorage.getItem('accessToken')
  }

  getIdToken = () => {
    return localStorage.getItem('idToken')
  }

  getExpiration = () => {
    return localStorage.getItem('expiresAt')
  }

  getUserName = () => {
    return localStorage.getItem('name')
  }

  unlockAPI = (apiKey) => {
    const hmac = crypto.createHmac('sha256', `${process.env.REACT_APP_HMAC_KEY}`)
    hmac.update(apiKey)

    const storeFrontAPI = axios.create({
      headers: {Accept: 'application/json'},
      responseType: 'json',
      baseURL: `${process.env.REACT_APP_STOREFRONT_API}`
    })

    return storeFrontAPI.post('/unlock', {apiKey: hmac.digest('hex')}).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('isAPIUnlocked', true)
      }
      return response
    })
  }

  setSession = (authResult) => {
    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    const decoded = jwt.decode(authResult.idToken)
    localStorage.setItem('accessToken', authResult.accessToken)
    localStorage.setItem('idToken', authResult.idToken)
    localStorage.setItem('expiresAt', expiresAt)
    localStorage.setItem('name', decoded.name)
  }

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (err) {
        this.logout()
        // eslint-disable-next-line no-console
        console.log(`Could not get a new token (${err.error}: ${err.error_description}).`)
      }
    })
  }

  isAPIUnlocked = () => {
    return localStorage.getItem('isAPIUnlocked')
  }

  logout = () => {
    // Remove tokens and expiry time
    localStorage.removeItem('accessToken')
    localStorage.removeItem('idToken')
    localStorage.removeItem('expiresAt')
    localStorage.removeItem('isAPIUnlocked')
    localStorage.removeItem('isLoggedIn')

    // navigate to the logout route
    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
      client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`
    })
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.getExpiration()
    let idToken = this.getIdToken()

    if (!idToken) return false

    if (new Date().getTime() > expiresAt && idToken) {
      this.renewSession()
      expiresAt = this.getExpiration()
    }

    return new Date().getTime() < expiresAt && idToken
  }

  login = (isSignUp) => {
    const loginType = isSignUp ? Auth0LoginTypeEnum.SignUp : Auth0LoginTypeEnum.Login
    this.getUniversalLoginLock(loginType)
    this.auth0.authorize({action: loginType})
  }
}
