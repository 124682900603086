import {ACTION_TYPES} from './actions'

const initialState = {}
export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.ACCOUNTS_REQUEST_SUCCESS:
      return Object.assign({}, payload)
    case ACTION_TYPES.ACCOUNTS_REQUEST_FAILURE:
      return Object.assign({}, payload.error)
    case ACTION_TYPES.ACCOUNTS_CREATE_REQUEST_SUCCESS:
      return Object.assign({}, payload)
    case ACTION_TYPES.ACCOUNTS_CREATE_REQUEST_FAILURE:
      return Object.assign({}, payload.error)
    case ACTION_TYPES.ACCOUNTS_UPDATE_PAYMENT_METHOD:
      return Object.assign({}, state, payload)
    default:
      return state
  }
}
