import axios from 'axios'
import {productsRequest, productsFailure, productsSuccess} from './actions.js'

const axiosClient = axios.create({
  headers: {
    Accept: 'application/json'
  },
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_CACHE
})

export function getProductsByID() {
  return function(dispatch) {
    const resolvedProducts = Promise.resolve(axiosClient.get('/products_id.json'))
    dispatch(productsRequest())
    return resolvedProducts.then(function(response) {
      if (response.status < 400) {
        dispatch(productsSuccess(response.data))
      } else {
        dispatch(productsFailure(response.data))
        throw new Error('error retrieving products')
      }
    })
  }
}
