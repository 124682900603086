import axios from 'axios'
import verifier from '../../utils/jwtVerifier'
import authService from '../../services/authService'

import {
  subscriptionsRequest,
  subscriptionsFailure,
  subscriptionsSuccess,
  createSubscriptionWithBaseProduct,
  createSubscriptionAddEnhancement,
  createSubscriptionRemoveEnhancement,
  createSubscriptionAddPaymentMethod,
  createSubscriptionCreateAutoBill,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  updateSubscriptionRemoveEnhancement,
  updateSubscriptionAddEnhancement,
  updateSubscriptionWithBaseProduct,
  updateSubscriptionUpdateAutoBill
} from './actions'

import {productsFailure} from '../products/actions'

const axiosClient = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

const axiosClientProducts = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_CACHE
})

const axiosSubscription = axios.create({
  headers: {Accept: 'application/json'},
  responseType: 'json',
  baseURL: process.env.REACT_APP_CASHBOX_PROXY
})

export function addPaymentMethod(paymentId) {
  return function(dispatch) {
    return Promise.resolve(dispatch(createSubscriptionAddPaymentMethod(paymentId)))
  }
}

export function cancelSubscriptionAutoBill(subscriptionId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          cancelSubscriptionFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const cancelAutoBill = axiosSubscription.post(
      `/subscriptions/${subscriptionId}/actions/cancel?settle=true&disentitle=true`,
      null,
      {
        headers: {
          Authorization: authSvc.getIdToken()
        }
      }
    )

    dispatch(cancelSubscriptionRequest())
    return cancelAutoBill.then((response) => {
      if (response.data.object === 'Error') {
        dispatch(cancelSubscriptionFailure(response.data))
        throw new Error('Error cancelling subscription')
      } else {
        dispatch(cancelSubscriptionSuccess(response.data))
      }
    })
  }
}

export function createSubscriptionAutoBill(createSubscriptionObject) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const createAutoBill = axiosSubscription.post('/subscriptions', createSubscriptionObject, {
      headers: {
        Authorization: authSvc.getIdToken()
      }
    })

    return createAutoBill.then((response) => {
      if (response.data.object === 'Error') {
        dispatch(createSubscriptionCreateAutoBill(response.data))
        throw new Error('error creating autobill')
      } else {
        dispatch(createSubscriptionCreateAutoBill(response.data))
      }
    })
  }
}

export function updateSubscriptionAutoBill(subscriptionObject) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const updateAutoBill = axiosSubscription.post(
      `/subscriptions/${subscriptionObject.id}?bill_prorated_period=true&effective_date=today`,
      subscriptionObject,
      {
        headers: {
          Authorization: authSvc.getIdToken()
        }
      }
    )

    return updateAutoBill.then((response) => {
      if (response.data.object === 'Error') {
        dispatch(updateSubscriptionUpdateAutoBill(response.data))
        throw new Error('error updating autobill')
      } else {
        dispatch(updateSubscriptionUpdateAutoBill(response.data))
      }
    })
  }
}

export function updateSubscriptionAddEnhancements(subscriptionItem, selectedProductId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              updateSubscriptionAddEnhancement({
                subscriptionItem,
                selectedProductId,
                products: response.data,
                accountId: accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function updateSubscriptionRemoveEnhancements(subscriptionItem, selectedProductId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              updateSubscriptionRemoveEnhancement({
                subscriptionItem,
                selectedProductId,
                products: response.data,
                accountId: accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function createSubscriptionAddEnhancements(selectedProductId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              createSubscriptionAddEnhancement({
                selectedProductId,
                products: response.data,
                accountId: accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function createSubscriptionRemoveEnhancements(selectedProductId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              createSubscriptionRemoveEnhancement({
                selectedProductId,
                products: response.data,
                accountId: accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function updateSubscriptionAddBaseProduct(selectedProductId, currentBaseSubscriptionItem) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              updateSubscriptionWithBaseProduct({
                selectedProductId,
                currentBaseSubscriptionItem,
                products: response.data,
                accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function createSubscriptionAddBaseProduct(selectedProductId) {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountId = dataDecoded.sub
    if (accountId) {
      const resolvedProducts = axiosClientProducts.get('/products_id.json', {
        headers: {Authorization: authSvc.getIdToken()}
      })

      return resolvedProducts.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(
              createSubscriptionWithBaseProduct({
                selectedProductId,
                products: response.data,
                accountId: accountId
              })
            )
          }
        } else {
          dispatch(productsFailure(response))
          throw new Error('error retrieving products')
        }
      })
    }
  }
}

export function getSubscriptions() {
  return function(dispatch) {
    const authSvc = new authService()
    const {dataDecoded, errDecoded} = verifier(authSvc.getIdToken())
    if (errDecoded) {
      return Promise.reject(
        dispatch(
          subscriptionsFailure({
            status: errDecoded.status,
            statusText: errDecoded.statusText,
            data: []
          })
        )
      )
    }

    const accountID = dataDecoded.sub
    if (accountID) {
      const resolvedSubscriptions = axiosClient.get(`/subscriptions?account=${encodeURI(accountID)}`, {
        headers: {Authorization: authSvc.getIdToken()}
      })

      dispatch(subscriptionsRequest())
      return resolvedSubscriptions.then((response) => {
        if (response.status < 400) {
          if (response.data.object !== 'Error') {
            dispatch(subscriptionsSuccess(response.data))
            return response.data
          } else {
            // this means that the cashbox subscription doesn't exist so we need to gin up an empty subscription
            // until it can be replaced later
            response.status = 200
            response.data = []
            response.total_count = 0
            dispatch(subscriptionsSuccess(response))
          }
        } else {
          dispatch(subscriptionsFailure(response))
          throw new Error('error getting subscription for account')
        }
      })
    }
  }
}
