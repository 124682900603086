import AuthService from './authService'
import {Auth0LoginTypeEnum} from './Auth0UniversalLock'

export default class AuthSvc {
  constructor() {
    return {
      ...new AuthService(),
      Auth0LoginType: Auth0LoginTypeEnum
    }
  }
}
